/* base */
import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { isInViewport } from "utils/isInViewport";
import { useTitle, useHoverDirty } from "react-use";

/* assets */
import { colors } from "theme/colors";

/* components */
import { Module } from "components/module";
import {
  Box,
  Boxes,
  Title,
  Section1,
  Subtitle,
  SectionTitle,
  MobileSpacer,
  Section2Boxes,
} from "modules/about/styles";
import { Soundwaves } from "modules/about/components/soundwaves";
import { TitleElement } from "modules/volunteer/components/titleelement";
import { SideSoundwaves } from "modules/about/components/sidesoundwaves";
import { Footer } from "components/footer";
import { ScrollIndicator } from "components/scrollindicator";

const background1 = `linear-gradient(180deg, ${colors.breachLime} 80%, ${colors.white} 20%);-moz-linear-gradient(180deg, ${colors.breachLime} 80%, ${colors.white} 20%);-o-linear-gradient(180deg, ${colors.breachLime} 80%, ${colors.white} 20%);-webkit-linear-gradient(180deg, ${colors.breachLime} 80%, ${colors.white} 20%);`;

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -200px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const vline = <span style={{ fontWeight: 800, color: colors.black }}>|</span>;

export const FunkyTitle = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    transform: rotate(15deg);
    animation: ${(props) =>
      props.noAnimation ? "none" : "funkyColoredLetters 1.5s infinite linear"};

    &:nth-child(1) {
      animation-delay: 0.25s;
    }

    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation-delay: 0.75s;
    }

    &:nth-child(4) {
      animation-delay: 1s;
    }

    &:nth-child(5) {
      animation-delay: 1.25s;
    }

    &:nth-child(6) {
      animation-delay: 1.5s;
    }

    ${(props) =>
      props.extra &&
      `
      &:nth-child(1) {
        animation-delay: 0.2s;
      }

      &:nth-child(2) {
        animation-delay: 0.4s;
      }

      &:nth-child(3) {
        animation-delay: 0.6s;
      }

      &:nth-child(4) {
        animation-delay: 0.8s;
      }

      &:nth-child(5) {
        animation-delay: 1s;
      }

      &:nth-child(6) {
        animation-delay: 1.2s;
      }
    
      &:nth-child(7) {
        animation-delay: 1.4s;
      }

      &:nth-child(8) {
        animation-delay: 1.6s;
      }
    `}
  }
`;

export function BreachFestivalModule() {
  useTitle("Breach - Make Music Cyprus");

  const hoverContainer1 = React.useRef();
  const isHovering1 = useHoverDirty(hoverContainer1);
  const hoverContainer2 = React.useRef();
  const isHovering2 = useHoverDirty(hoverContainer2);
  const hoverContainer3 = React.useRef();
  const isHovering3 = useHoverDirty(hoverContainer3);

  function onScroll() {
    const anchors = document.getElementsByClassName("anchor");
    if (anchors) {
      const element1 = document.getElementById("anchor-1");
      if (element1) {
        const result1 = isInViewport(element1);
        if (result1) {
          anchors[0].classList.add("title-line-2");
        } else {
          anchors[0].classList.remove("title-line-2");
        }
      }
      const element2 = document.getElementById("anchor-2");
      if (element2) {
        const result2 = isInViewport(element2);
        if (result2) {
          anchors[1].classList.add("title-line-1-3");
        } else {
          anchors[1].classList.remove("title-line-1-3");
        }
      }
      const element3 = document.getElementById("anchor-3");
      if (element3) {
        const result3 = isInViewport(element3);
        if (result3) {
          anchors[2].classList.add("title-line-2");
        } else {
          anchors[2].classList.remove("title-line-2");
        }
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Breach Festival by Make Music Cyprus"
        />
      </Helmet>
      <Module background={background1}>
        <Section1 style={{ marginTop: -50 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                gap: 10,
                marginTop: -53,
                transformOrigin: "top",
                transform: "rotate(180deg)",
              }}
            >
              <TitleElement color={colors.black} />
            </div>
            <TitleElement color={colors.black} />
          </div>
          <Title style={{ marginBottom: 20 }}>
            <FunkyTitle
            // style={{
            //   justifyContent: "center",
            // }}
            >
              <div>B</div>
              <div>R</div>
              <div>E</div>
              <div>A</div>
              <div>C</div>
              <div>H</div>
            </FunkyTitle>
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FunkyTitle noAnimation>
                <div>F</div>
                <div>E</div>
                <div>S</div>
                <div>T</div>
                <div>I</div>
                <div>V</div>
                <div>A</div>
                <div>L</div>
              </FunkyTitle>
              <div style={{ width: 20 }} />
              <FunkyTitle>
                <div>2</div>
                <div>0</div>
                <div>2</div>
                <div>3</div>
              </FunkyTitle>
            </div> */}
          </Title>
          <Subtitle color={colors.black}>
            IMPROVISED & EXPERIMENTAL MUSIC FESTIVAL
            <br />
            2ND EDITION
          </Subtitle>
        </Section1>
        <ScrollIndicator color={colors.breachLime} />
      </Module>
      <Reveal triggerOnce keyframes={customAnimation}>
        <Module
          responsiveHeight="fit-content"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          background={colors.white}
        >
          <SectionTitle>
            <h3 className="anchor" id="anchor-1">
              <b>IMPROVISED & EXPERIMENTAL</b>
              <br />
              MUSIC FESTIVAL IN NICOSIA
            </h3>
            <br />
            <h4>
              Breach is a festival dedicated to Improvisation
              <br />
              and Experimental music practices
            </h4>
          </SectionTitle>
          <Section2Boxes>
            <div
              className="section-2-box"
              //   ref={hoverContainer1}
              style={{
                color: colors.black,
                background: colors.breachLime,
              }}
            >
              {!isHovering1 ? (
                <React.Fragment>
                  <h6>WHEN</h6>
                  <div>
                    7th & 8th
                    <br />
                    <br />
                    April 2024
                    <br />
                    <br />
                    20:30
                    <br />
                    <br />
                    Doors open at 20:00
                  </div>
                </React.Fragment>
              ) : (
                <div className="paragraph-container">
                  <p></p>
                </div>
              )}
            </div>
            <div
              className="section-2-box"
              //   ref={hoverContainer2}
              style={{
                color: colors.black,
                background: colors.breachLime,
              }}
            >
              {!isHovering2 ? (
                <React.Fragment>
                  <h6>WHERE</h6>
                  <div>
                    Ktirion 53
                    <br />
                    <br />
                    Center of experimental arts of Nicosia
                    <br />
                    <br />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://maps.app.goo.gl/AySHKEASaQAZ4dvE7"
                      style={{
                        bottom: 35,
                        position: "absolute",
                        pointerEvents: "auto",
                        color: colors.black,
                      }}
                    >
                      See location
                    </a>
                  </div>
                </React.Fragment>
              ) : (
                <div className="paragraph-container">
                  <p></p>
                </div>
              )}
            </div>
            <div
              className="section-2-box"
              //   ref={hoverContainer3}
              style={{
                color: colors.black,
                background: colors.breachLime,
              }}
            >
              {!isHovering3 ? (
                <React.Fragment>
                  <h6>TICKETS</h6>
                  <div>
                    €12 {vline} day
                    <br />
                    <br />
                    €20 {vline} both days
                    <br />
                    <br />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.soldoutticketbox.com/event/breach-festival-3rd-edition"
                      style={{
                        bottom: 35,
                        position: "absolute",
                        pointerEvents: "auto",
                        color: colors.black,
                      }}
                    >
                      Book tickets
                    </a>
                  </div>
                </React.Fragment>
              ) : (
                <div className="paragraph-container">
                  <p></p>
                </div>
              )}
            </div>
            <Soundwaves color={colors.black} />
          </Section2Boxes>
          <MobileSpacer alwaysView />
        </Module>
      </Reveal>
      <Module
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        background={colors.white}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            background: "url(/posters/breach-poster.webp)",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
        {/* <video
          loop
          muted
          autoPlay
          style={{
            height: "100vh",
            objectFit: "cover",
          }}
        >
          <source src="/posters/breach-poster.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
      </Module>
      <Reveal triggerOnce keyframes={customAnimation}>
        <Module
          display="flex"
          alignItems="center"
          smHeight="fit-content"
          minHeight="100vh"
          flexDirection="column"
          justifyContent="center"
          background={colors.white}
        >
          <MobileSpacer alwaysView />
          <SectionTitle oppositeLine>
            <h3 className="anchor" id="anchor-2">
              <b>BREACH</b>
              <br />
              MUSIC FESTIVAL
            </h3>
            <br />
            <h4>Improvised & Experimental</h4>
          </SectionTitle>
          <Boxes noCol>
            <Box
              id="paragraph-text"
              color={colors.breachLime}
              textColor={colors.black}
            >
              Breach is a festival dedicated to Improvisation and Experimental
              music. Breach’s mission is to boost and support these practices in
              Cyprus: free improvisation, free jazz, contemporary classical
              music, experimental electronic music, sound-art, as well as many
              other uncharted music making traditions. This year is the second
              edition of the Breach Festival in partnership with the Irtijal
              festival in Lebanon - the largest festival in Beirut and Space21
              festival in Kurdistan/Iraq. The participating musicians come from
              Cyprus, Lebanon, Kurdistan/Iraq, and Germany, and are accomplished
              in the field of improvisation and experimental music with a long
              history of performing at recognised venues and festivals around
              the world.
            </Box>
            <Box
              id="paragraph-text"
              color={colors.breachLime}
              textColor={colors.black}
            ></Box>
            <SideSoundwaves
              color={colors.black}
              position="left: -48%;bottom: 60%;"
            />
          </Boxes>
        </Module>
      </Reveal>
      <MobileSpacer alwaysView color={colors.white} />
      <Footer
        textColor={colors.black}
        style={{ borderTopColor: colors.black }}
      />
    </React.Fragment>
  );
}
