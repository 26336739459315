/* base */
import React from "react";

/* assets */
import VideoBGSVG from "icons/Homepage_Background.svg";

/* components */
import { Module } from "components/module";
import {
  VideoBG,
  InnerModule3,
  Module3PaddingTop,
} from "modules/home/modules/module3/styles";

export const HomeModule3 = (props) => (
  <Module zIndex={2} responsiveHeight="35vh">
    <InnerModule3>
      <VideoBG opacity={0.5} url={VideoBGSVG} />
      <Module3PaddingTop />
      <iframe
        title="video"
        allowFullScreen
        frameBorder="0"
        src={`https://www.youtube.com/embed/${props.videoId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </InnerModule3>
  </Module>
);
