/* base */
import styled from "styled-components";
import { colors } from "theme/colors";
import { fontsizes } from "theme/fontsizes";

export const SupportGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 30px;
  position: relative;
  grid-template-areas:
    "text1 image"
    "text2 image";

  @media (max-width: 992px) {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-template-areas:
      "text1"
      "image"
      "text2";
  }

  .support-image {
    @media (max-width: 992px) {
      width: 90%;
      height: 300px !important;
    }
  }
`;

export const TextBox = styled.div`
  width: 350px;
  padding: 35px;
  line-height: 23px;
  position: relative;
  height: fit-content;
  border-radius: 10px;
  color: ${colors.white};
  font-size: ${fontsizes.text};
  background: ${colors.bgturquoise2};

  a {
    color: ${colors.white};
    &:hover {
      color: ${colors.bgturquoise1};
    }
  }

  @media (max-width: 992px) {
    margin: 15px 0;
    width: calc(90% - 70px);
  }
`;

export const DonateSection = styled.div`
  width: 880px;
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
    display: flex;
    flex-direction: row;

    > p {
      line-height: 23px;
      color: ${colors.black};
      width: calc(50% - 10px);
      font-size: ${fontsizes.text};

      &:first-of-type {
        margin-right: 10px;
      }
      &:last-of-type {
        margin-left: 10px;
      }
    }
  }

  @media (max-width: 992px) {
    width: 90%;

    > div {
      flex-direction: column;
      > p {
        width: 100%;
        &:first-of-type {
          margin-right: 0;
        }
        &:last-of-type {
          margin-left: 0;
        }
      }
    }
  }
`;
