import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export function LoaderComponent() {
  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        zIndex: 1000,
        width: "100%",
        height: "100%",
        display: "flex",
        position: "fixed",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <Box
        sx={{
          padding: "8px",
          display: "flex",
          borderRadius: "4px",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
        }}
      >
        <CircularProgress
          sx={{
            color: "#f05a23",
          }}
        />
      </Box>
    </Box>
  );
}
