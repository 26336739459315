import styled from "styled-components";
import { colors } from "../../theme/colors";

export const LineUpContainer = styled.div`
  z-index: 1;
  width: 80%;
  min-height: calc(100vh - 120px);

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const LineUpBackground = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-position: right;
  background-image: url(/line-up-background.jpg);

  @media (max-width: 768px) {
    background-size: cover;
    background-image: url(/line-up-background-mobile.jpg);
  }
`;

export const LineUpTitle = styled.h1`
  margin: 0;
  color: #fff;
  font-size: 9rem;
  font-weight: 900;

  > i {
    letter-spacing: 5px;
  }

  @media (max-width: 768px) {
    font-size: 6rem;
  }
`;

export const LineUpAnchorsContainer = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const Anchor = styled.div`
  color: #fff;
  font-size: 2.5rem;
  width: fit-content;
  font-weight: normal;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: ${colors.orange1};
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const LineUpSpot = styled.div`
  display: flex;
  margin-bottom: 80px;
  flex-direction: column;

  > h2 {
    margin: 0;
    color: #fff;
    font-size: 3.5rem;
    font-weight: normal;

    > span {
      color: #fff;
      font-size: 6rem;
      font-weight: 900;
      padding-left: 60px;
    }

    > div {
      gap: 25px;
      display: flex;
      align-items: center;

      > #dot {
        width: 35px;
        height: 35px;
        background: #fff;
        border-radius: 50%;
      }
    }
  }

  @media (max-width: 768px) {
    > h2 {
      font-size: 2.5rem;

      > span {
        font-size: 4rem;
      }
    }
  }

  > div {
    gap: 25px;
    display: flex;
    margin-left: 17px;
    padding-left: 43px;
    flex-direction: column;
    border-left: 1px solid #fff;
  }
`;

export const LineUpSlot = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;

  > span {
    color: #fff;
    font-size: 2.5rem;
    font-weight: normal;

    &:first-of-type {
      font-size: 2rem;
      font-weight: 900;
    }
  }

  @media (max-width: 768px) {
    > span {
      font-size: 2rem;
    }
  }
`;

export const BackToTop = styled.div`
  right: 20px;
  bottom: 20px;
  position: fixed;
  cursor: pointer;
`;
