export const colors = {
  white: "#fff",
  black: "#000",
  gray: "#ccc",
  clear: "transparent",
  orange1: "#f05a23",
  orange2: "#ff8d13",
  bgwhite: "#f7efe5",
  bgblue: "#127ebf",
  bgyellow: "#feb457",
  bgblue1: "#1f5c70",
  bgblue2: "#85b6c1",
  aboutsoundwave: "#f5f2ec",
  sidesoundwaves: "#6e8f97",
  bgred1: "#c71616",
  bgred2: "#e96769",
  bggrey1: "#78837d",
  bggrey2: "#e1e2de",
  bgturquoise1: "#62c7d4",
  bgturquoise2: "#099ba3",
  bgyellow1: "#cac251",
  bgyellow2: "#f4d522",
  bgyellow3: "#fdbb14",
  breachBlue: "#211A56",
  breachGreen: "#21D905",
  breachLime: "#CCFD03",
  breachPurple: "#542082",
  breachLime: "#78FF00",
  cablenetPurple: "#562d80",
  breachPink: "#FEC0FF",
  breachOrange: "#FF9900",
};
