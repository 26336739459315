import React from "react";
import {
  Body,
  Title,
  Subtitle,
  Container,
  Animation,
  CustomNavLink,
} from "modules/participate/components/card/styles";

export function Card(props) {
  return (
    <Container>
      <Animation>{props.icon}</Animation>
      <div style={{ marginBottom: 80 }}>
        <Title>{props.title}</Title>
        <Subtitle>{props.subtitle}</Subtitle>
        <Body>{props.body}</Body>
      </div>
      <CustomNavLink to={props.link}>{props.button}</CustomNavLink>
    </Container>
  );
}
