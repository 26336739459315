/* base */
import React from "react";
import { NavLink } from "react-router-dom";

/* components */
import { Module } from "components/module";
import { background5 } from "modules/home/data";
import { ImagesGrid } from "modules/home/components/imagesgrid";
import { InnerModule5 } from "modules/home/modules/module5/styles";

export const HomeModule5 = (props) => (
  <Module background={background5} minHeight="100vh">
    <InnerModule5>
      <div>
        <h3>
          <b>{props.title1}</b>
        </h3>
        <h3 id="3-third-h3" className="anchor">
          {props.title2}
        </h3>
        <br />
        <br />
      </div>
      <ImagesGrid images={props.images} />
      <br />
      <br />
      <div>
        <NavLink to="/gallery">{props.button}</NavLink>
      </div>
    </InnerModule5>
  </Module>
);
