/* base */
import React from "react";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import { useQuery } from "graphql-hooks";
import { isInViewport } from "utils/isInViewport";
import { useTitle, useUpdateEffect } from "react-use";
import useLocalStorageState from "use-local-storage-state";

/* assets */
import { colors } from "theme/colors";

/* components */
import { Module } from "components/module";
import { Footer } from "components/footer";
import { LoaderComponent } from "components/loader";
import { ScrollIndicator } from "components/scrollindicator";
import { SubscribeForm } from "modules/contact/common/subform";
import { Title, Subtitle, Section1 } from "modules/about/styles";
import { InnerModule5 } from "modules/home/modules/module5/styles";
import {
  TitleElement,
  ContactsGrid,
  QuarterCircle,
} from "modules/contact/styles";

const background1 = `linear-gradient(180deg, ${colors.orange1} 80%, ${colors.white} 20%);-moz-linear-gradient(180deg, ${colors.orange1} 80%, ${colors.white} 20%);-o-linear-gradient(180deg, ${colors.orange1} 80%, ${colors.white} 20%);-webkit-linear-gradient(180deg, ${colors.orange1} 80%, ${colors.white} 20%);`;

const BASE_CONTACT_QUERY = `query Contact($lang: SiteLocale) {
  contact {
    title(locale: $lang)
    subtitle(locale: $lang)
    emailLabel(locale: $lang)
    telephoneLabel(locale: $lang)
    subscribe(locale: $lang)
    required(locale: $lang)
    firstName(locale: $lang)
    lastName(locale: $lang)
    orgName(locale: $lang)
    emailAddress(locale: $lang)
    invalidEmail(locale: $lang)
    submit(locale: $lang)
  }
}`;

export function ContactModule() {
  useTitle("Contact - Make Music Cyprus");

  // const lang = useLocalStorageState("lang", {
  //   defaultValue: "en",
  // })[0];
  const lang = "en";
  const { loading, data, refetch } = useQuery(BASE_CONTACT_QUERY, {
    variables: { lang: lang || "en" },
  });

  function onScroll() {
    const anchors = document.getElementsByClassName("anchor");
    if (anchors) {
      const element1 = document.getElementById("anchor-1");
      if (element1) {
        const result1 = isInViewport(element1);
        if (result1) {
          anchors[0].classList.add("title-line-1-3");
        } else {
          anchors[0].classList.remove("title-line-1-3");
        }
      }
      const element2 = document.getElementById("anchor-2");
      if (element2) {
        const result2 = isInViewport(element2);
        if (result2) {
          anchors[1].classList.add("title-line-1-3");
        } else {
          anchors[1].classList.remove("title-line-1-3");
        }
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useUpdateEffect(() => {
    refetch({
      lang: lang || "en",
    });
  }, [lang]);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta name="description" content="Make Music Cyprus - Contact" />
      </Helmet>
      <Module background={background1}>
        <Section1>
          <div style={{ width: "100%", height: 40 }} />
          <TitleElement>
            <QuarterCircle />
            <QuarterCircle />
          </TitleElement>
          <Title>{get(data, "contact.title", "CONTACT")}</Title>
          <Subtitle>{get(data, "contact.subtitle", "SAY HELLO")}</Subtitle>
        </Section1>
        <ScrollIndicator color={colors.orange1} />
      </Module>
      <Module height="fit-content" responsiveHeight="fit-content">
        <InnerModule5 textColor={colors.black} noPaddingTop>
          {/* <div>
            <h3 id="anchor-1" className="anchor">
              <b>Contact information</b>
            </h3>
            <br />
            <br />
          </div> */}
          <ContactsGrid>
            <div className="contact-details">
              <b>{get(data, "contact.emailLabel", "Email")}</b>:{" "}
              <a href="mailto:info@makemusiccyprus.org">
                info@makemusiccyprus.org
              </a>
              <div style={{ width: "100%", height: 10 }} />
              <b>{get(data, "contact.telephoneLabel", "Telephone")}</b>:{" "}
              <a href="tel:0035770000216">+357 700 00 216</a>
            </div>
          </ContactsGrid>
          <br />
          <br />
          <br />
          <br />
        </InnerModule5>
      </Module>
      <Module height="fit-content" responsiveHeight="fit-content">
        <InnerModule5 textColor={colors.black} noPaddingTop>
          <div id="subscribe">
            <h3 id="anchor-1" className="anchor">
              <b>{get(data, "contact.subscribe", "subscribe")}</b>
            </h3>
            <br />
            <br />
          </div>
          <SubscribeForm data={data} />
          <br />
          <br />
          <br />
          <br />
        </InnerModule5>
      </Module>
      <Module height="fit-content" responsiveHeight="fit-content"></Module>
      <Footer />
    </React.Fragment>
  );
}
