import React from "react";
import { Container } from "modules/home/components/soundwaves/styles";

export const Soundwaves = () => (
  <React.Fragment>
    <div
      style={{
        top: "60vh",
        left: "0px",
        position: "absolute",
      }}
    >
      <Container>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </Container>
    </div>
    <div
      style={{
        top: "60vh",
        right: "330px",
        position: "absolute",
      }}
    >
      <Container opposite={true}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </Container>
    </div>
  </React.Fragment>
);
