import React from "react";
import styled from "styled-components";
import { colors } from "theme/colors";

const Container = styled.div`
  width: 114px;
  height: 114px;
  position: relative;
  margin: -90px 0 10px 0;
  animation: spin 2s infinite linear;
`;

const OutterCircle = styled.div`
  position: relative;

  > .arc {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    border: 7px solid;
    border-radius: 100%;
    border-color: ${(props) => props.color};
  }
  > .arc_start {
    border-color: ${(props) => props.color} transparent;
    -webkit-transform: rotate(-100deg);
    -moz-transform: rotate(-100deg);
    -ms-transform: rotate(-100deg);
    -o-transform: rotate(-100deg);
    transform: rotate(-100deg);
  }
  > .arc_end {
    border-color: transparent ${(props) => props.color}
      ${(props) => props.color} ${(props) => props.color};
    -webkit-transform: rotate(-110deg);
    -moz-transform: rotate(-110deg);
    -ms-transform: rotate(-110deg);
    -o-transform: rotate(-110deg);
    transform: rotate(-110deg);
  }
`;

const InnerCircle = styled.div`
  margin: 16px;
  position: absolute;

  .arc {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 70px;
    border: 6px solid;
    border-color: ${(props) => props.color};
    border-radius: 100%;
  }
  .arc_start {
    border-color: ${(props) => props.color} transparent;
    -webkit-transform: rotate(-100deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .arc_end {
    border-color: transparent ${(props) => props.color}
      ${(props) => props.color} ${(props) => props.color};
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
`;

const CenterCircle = styled.div`
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin: 30px;
  position: absolute;
  border-radius: 50%;
  border: 3px solid ${(props) => props.color};
`;

export const TitleElement = ({ color = colors.white }) => (
  <Container>
    <OutterCircle color={color}>
      <div className="arc arc_start" />
      <div className="arc arc_end" />
      <InnerCircle color={color}>
        <div className="arc arc_start" />
        <div className="arc arc_end" />
        <CenterCircle color={color} />
      </InnerCircle>
    </OutterCircle>
  </Container>
);
