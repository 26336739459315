import React from "react";
import styled from "styled-components";
import { colors } from "theme/colors";

const Container = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
`;

const Square = styled.div`
  top: -25%;
  margin: auto;
  width: 100px;
  height: 100px;
  z-index: 1;
  position: absolute;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: ${(props) => props.color || colors.bgturquoise2};
    content: "";
    margin: -10%;
    box-shadow: inset 0 0 0 8px;
    animation: clipMe 2s infinite linear;
    clip: rect(110px, 120px, 120px, 0px);
  }

  &:before {
    animation-delay: -0.5s;
  }
`;

export const TitleElement = (props) => (
  <Container>
    <Square color={props.color} />
  </Container>
);
