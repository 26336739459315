import React from "react";
import { Container } from "modules/about/components/soundwaves/styles";

const commonstyle = {
  top: "calc(50% + 25px)",
  position: "absolute",
  transform: "scale(0.4)",
};

export const Soundwaves = (props) => (
  <React.Fragment>
    <div
      style={{
        ...commonstyle,
        left: "-95px",
      }}
    >
      <Container color={props.color}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </Container>
    </div>
    <div
      style={{
        ...commonstyle,
        right: "41px",
      }}
    >
      <Container opposite={true} color={props.color}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </Container>
    </div>
  </React.Fragment>
);
