import React from "react";
import {
  Container,
  SuperContainer,
} from "modules/about/components/sidesoundwaves/styles";

const commonstyle = {
  transform: "scale(0.4)",
};

export const SideSoundwaves = (props) => (
  <SuperContainer
    id="participate-anim"
    position={props.position}
    responsivePosition={props.responsivePosition}
  >
    <div style={commonstyle}>
      <Container color={props.color}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </Container>
    </div>
    <div style={{ width: "70px" }}></div>
    <div style={commonstyle}>
      <Container opposite color={props.color}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </Container>
    </div>
  </SuperContainer>
);
