import React from "react";

export const Loader = () => (
  <svg width="64px" height="64px" viewBox="0 0 128 128">
    <g>
      <path d="M59.6 0h8v40h-8V0z" fill="#f05a23" />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#fcded3"
        transform="rotate(30 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#fcded3"
        transform="rotate(60 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#fcded3"
        transform="rotate(90 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#fcded3"
        transform="rotate(120 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#facdbd"
        transform="rotate(150 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#f9bda7"
        transform="rotate(180 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#f7ac91"
        transform="rotate(210 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#f69c7b"
        transform="rotate(240 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#f48b65"
        transform="rotate(270 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#f37b4f"
        transform="rotate(300 64 64)"
      />
      <path
        d="M59.6 0h8v40h-8V0z"
        fill="#f16a39"
        transform="rotate(330 64 64)"
      />
      <animateTransform
        attributeName="transform"
        type="rotate"
        values="0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64"
        calcMode="discrete"
        dur="960ms"
        repeatCount="indefinite"
      ></animateTransform>
    </g>
  </svg>
);
