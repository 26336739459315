import React from "react";
import { TagCloud } from "react-tagcloud";
import { fontsizes } from "theme/fontsizes";
import { strRandomGen } from "utils/strrandomgen";

const sentence = [
  {
    count: 50,
    value: "MAKE",
    fontWeight: "600",
    fontFamily: "Open",
    fontSize: fontsizes.wordcloud1,
  },
  {
    count: 50,
    value: "MUSIC",
    fontWeight: "600",
    fontFamily: "Open",
    fontSize: fontsizes.wordcloud1,
  },
  {
    count: 50,
    value: "AROUND",
    fontWeight: "100",
    fontFamily: "Open",
    fontSize: fontsizes.wordcloud2,
  },
  {
    count: 50,
    value: "THE",
    fontWeight: "100",
    fontFamily: "Open",
    fontSize: fontsizes.wordcloud2,
  },
  {
    count: 50,
    value: "WORLD",
    fontWeight: "400",
    fontFamily: "Open",
    fontSize: fontsizes.wordcloud1,
  },
];

const data = [
  ...sentence,
  ...sentence,
  ...sentence,
  ...sentence,
  ...sentence,
  ...sentence,
  ...sentence,
  ...sentence,
  ...sentence,
];

const customRenderer = (tag, size, color) => (
  <span
    key={strRandomGen()}
    style={{
      animation: "blinker 3s linear infinite",
      animationDelay: `${Math.random() * 2}s`,
      fontSize: tag.fontSize,
      margin: "3px 10px",
      padding: "3px",
      fontWeight: tag.fontWeight,
      fontFamily: tag.fontFamily,
      display: "inline-block",
      color: "white",
      opacity: 0.8,
    }}
  >
    {tag.value}
  </span>
);

export function Wordcloud() {
  return (
    <TagCloud
      tags={data}
      minSize={0}
      maxSize={100}
      shuffle={false}
      renderer={customRenderer}
    />
  );
}
