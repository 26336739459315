/* base */
import React from "react";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import { useQuery } from "graphql-hooks";
import { isInViewport } from "utils/isInViewport";
import { useTitle, useUpdateEffect } from "react-use";
import ResponsiveGallery from "react-responsive-gallery";
import useLocalStorageState from "use-local-storage-state";

/* assets */
import { colors } from "theme/colors";

/* components */
import { Module } from "components/module";
import { Footer } from "components/footer";
import { LoaderComponent } from "components/loader";
import { FooterContainer } from "modules/gallery/styles";
import { ScrollIndicator } from "components/scrollindicator";
import { TitleElement } from "modules/gallery/components/titleelement";
import { Title, Section1, Subtitle, SectionTitle } from "modules/about/styles";

const background1 = `linear-gradient(180deg, ${colors.bggrey1} 80%, ${colors.white} 20%);-moz-linear-gradient(180deg, ${colors.bggrey1} 80%, ${colors.white} 20%);-o-linear-gradient(180deg, ${colors.bggrey1} 80%, ${colors.white} 20%);-webkit-linear-gradient(180deg, ${colors.bggrey1} 80%, ${colors.white} 20%);`;

const BASE_GALLERY_QUERY = `query Gallery($lang: SiteLocale) {
  gallery {
    title(locale: $lang)
    subtitle(locale: $lang)
    sections(locale: $lang) {
      title
      subtitle
      images {
        url
      }
    }
  }
}`;

export function GalleryModule() {
  useTitle("Gallery - Make Music Cyprus");

  // const lang = useLocalStorageState("lang", {
  //   defaultValue: "en",
  // })[0];
  const lang = "en";
  const { loading, data, refetch } = useQuery(BASE_GALLERY_QUERY, {
    variables: { lang: lang || "en" },
  });

  function onScroll() {
    const anchors = document.getElementsByClassName("anchor");
    if (anchors) {
      const element1 = document.getElementById("anchor-1");
      if (element1) {
        const result1 = isInViewport(element1);
        if (result1) {
          anchors[0].classList.add("title-line-2");
        } else {
          anchors[0].classList.remove("title-line-2");
        }
      }
      const element2 = document.getElementById("anchor-2");
      if (element2) {
        const result2 = isInViewport(element2);
        if (result2) {
          anchors[1].classList.add("title-line-2");
        } else {
          anchors[1].classList.remove("title-line-2");
        }
      }
      const element3 = document.getElementById("anchor-3");
      if (element3) {
        const result3 = isInViewport(element3);
        if (result3) {
          anchors[2].classList.add("title-line-2");
        } else {
          anchors[2].classList.remove("title-line-2");
        }
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useUpdateEffect(() => {
    refetch({
      lang: lang || "en",
    });
  }, [lang]);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Make Music Cyprus - MUSIC SPEAKS IN A THOUSAND COLOURS"
        />
      </Helmet>
      <Module background={background1}>
        <Section1>
          <TitleElement />
          <Title>{get(data, "gallery.title", "GALLERY")}</Title>
          <Subtitle>
            {get(
              data,
              "gallery.subtitle",
              "MUSIC SPEAKS IN A THOUSAND COLOURS"
            )}
          </Subtitle>
        </Section1>
        <ScrollIndicator color={colors.bggrey1} />
      </Module>
      {get(data, "gallery.sections", []).map((section, index) => (
        <React.Fragment key={index}>
          <Module
            style={{ zIndex: 1 }}
            display="flex"
            alignItems="center"
            height="fit-content"
            flexDirection="column"
            justifyContent="center"
          >
            <SectionTitle>
              <h3 className="anchor" id="anchor-1">
                <b>{get(section, "title", "")}</b>
                <br />
                {get(section, "subtitle", "")}
              </h3>
            </SectionTitle>
            <br />
            <br />
            <br />
            <br />
            <ResponsiveGallery
              useLightBox
              imagesStyle="gallery-image"
              lightBoxAdditionalProps={{ enableZoom: false }}
              colsPadding={{ xs: 1, s: 1, m: 1, l: 1, xl: 1, xxl: 1 }}
              numOfImagesPerRow={{ xs: 2, s: 4, m: 4, l: 4, xl: 4, xxl: 4 }}
              imagesPaddingBottom={{ xs: 1, s: 1, m: 1, l: 1, xl: 1, xxl: 1 }}
              images={get(section, "images", []).map((image) => ({
                src: image.url,
              }))}
            />
          </Module>
          <div
            style={{
              zIndex: 0,
              height: 400,
              opacity: 0.5,
              width: "100%",
              marginTop: -300,
              marginBottom: 200,
              background: colors.bggrey1,
            }}
          />
        </React.Fragment>
      ))}
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </React.Fragment>
  );
}
