import styled from "styled-components";
import { colors } from "theme/colors";
import { fontsizes } from "theme/fontsizes";
import { fontweights } from "theme/fontweights";

export const Section2 = styled.div`
  display: flex;
  padding: 0 10vw;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 0 5vw;
  }

  @media (max-width: 600px) {
    #participate-anim {
      display: none;
    }
  }
`;

export const Section2Up = styled.div`
  width: 100%;
  height: 35vh;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
`;

export const Section2Down = styled.div`
  width: 100%;
  //   height: 50vh;
  display: flex;
  position: relative;
  flex-direction: column;

  .anchor {
    position: relative;
    &:before,
    &:after {
      content: "";
      width: 100vw;
      bottom: -15px;
      position: absolute;
      border: 1px solid ${colors.black};
      transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
      transform: translateX(${(props) => (props.oppositeLine ? "-" : "")}200%);
    }
  }

  > h4 {
    font-weight: 400;
    font-size: ${fontsizes.h4};
  }
`;

export const Section2Text = styled.h3`
  margin: 0;
  line-height: 1.5em;
  color: ${colors.black};
  font-size: ${fontsizes.h3};
  font-weight: ${fontweights.pagesub};

  @media (max-width: 500px) {
    font-size: 23px;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 75px 0;
  align-items: center;
  flex-direction: column;
  background: ${colors.orange2};

  > div {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: calc(100% - 20vw);
    justify-content: center;
    color: ${colors.orange2};
    background: ${colors.white};

    @media (max-width: 767px) {
      width: calc(100% - 10vw);
    }

    > div {
      padding: 0;
      width: 100%;
    }
  }
`;
