import { colors } from "theme/colors";
import styled from "styled-components";
import { fontsizes } from "theme/fontsizes";

export const TitleElement = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => (props.noMargin ? "" : "margin: -95px 0 35px 0;")}

  > div:nth-of-type(1) {
    transform: rotate(-45deg);
  }

  > div:nth-of-type(2) {
    transform: rotate(135deg);
  }
`;

export const QuarterCircle = styled.div`
  width: 50px;
  height: 50px;
  margin: 0 10px;
  border-radius: 50px 0 0 0;
  animation: quarter-circles 1.5s infinite linear;
  background: ${(props) => props.color || colors.white};
`;

export const FlexBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const ImageDiv = styled.div`
  width: 40%;
  height: 40vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.bgimg});

  @media (max-width: 1200px) {
    width: 100%;
    height: 25vh;
    margin-bottom: 50px;
  }
`;

export const ContainerGrid = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  font-size: 16px !important;
  justify-content: space-around;

  > div:first-of-type {
    margin-right: 24px;
  }

  @media (max-width: 1200px) {
    width: 100%;

    > div:first-of-type {
      width: 100%;
    }
  }
`;

export const ContactsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  > div {
    width: 50%;
    margin-top: 24px;
    margin-bottom: 24px;

    &:first-of-type {
      margin-top: 0px;
    }
    &:nth-of-type(2) {
      margin-top: 0px;
    }
  }

  @media (max-width: 767px) {
    > div {
      width: 100%;
      margin-top: 24px;
      margin-bottom: 24px;

      &:first-of-type {
        margin-top: 24px;
      }
      &:nth-of-type(2) {
        margin-top: 24px;
      }
    }
  }

  .contact-details {
    font-size: ${fontsizes.h4};
    a {
      color: ${colors.black};

      &:hover {
        color: ${colors.orange1};
      }
    }
  }
`;
