/* base */
import styled from "styled-components";
import { colors } from "theme/colors";
import { fontsizes } from "theme/fontsizes";
import { fontweights } from "theme/fontweights";

export const InnerModule5 = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: 10vh 10vw 0 10vw;
  ${(props) => (props.noPaddingTop ? "padding-top: 0;" : "")}

  > div:first-of-type {
    > h3 {
      margin: 5px 0;
      text-transform: uppercase;
      font-size: ${fontsizes.subtitle};
      font-weight: ${fontweights.subtitle};
      color: ${(props) => props.textColor || colors.white};

      span {
        font-weight: 100;
        font-family: Open;
        letter-spacing: 8px;
      }
    }

    .anchor {
      position: relative;
      margin-bottom: 24px;

      &:after {
        content: "";
        width: 100vw;
        bottom: -15px;
        position: absolute;
        transform: translateX(-200%);
        transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
        border: 1px solid ${(props) => props.textColor || colors.white};
      }
    }
  }

  // > div:nth-of-type(3) {
  //   top: 70vh !important;
  // }

  // > div:nth-of-type(4) {
  //   top: 44vh !important;
  // }

  > div:nth-of-type(3) {
    display: flex;
    margin: 40px 0;
    flex-direction: row;
    justify-content: start;
    
    > a {
      width: fit-content;
      // padding: 30px 80px;
        padding: 15px 80px;
        border-radius: 25px;
      text-decoration: none;
      color: ${colors.white};
      background: ${colors.clear};
      font-size: ${fontsizes.navlink};
      transition: background 0.5s ease;
      border: 2px solid ${colors.white};
      font-weight: ${fontweights.navlinkbold};

      @media (max-width: 1200px) {
        padding: 15px 40px;
      }

      &:hover {
        color: ${colors.white};
        background: ${colors.orange1};
        border-color: ${colors.orange1};
      }
    }
  }
}
`;
