import { colors } from "theme/colors";
import styled from "styled-components";
import { fontsizes } from "theme/fontsizes";
import { fontweights } from "theme/fontweights";

export const FormContainer = styled.div`
  color: ${colors.orange1};
  padding: 20vh 10vw 0 10vw;

  label {
    color: ${colors.black};
  }

  input[type="radio"],
  input[type="checkbox"] {
    margin: 0 15px 0 0;
  }

  @media (max-width: 600px) {
    padding: 20vh 5vw 0 5vw;
  }
`;

export const Title = styled.h1`
  margin: 0;
  width: 100%;
  font-size: ${fontsizes.detailpagetitle};

  @media (max-width: 900px) {
    margin-top: 30px;
  }
  @media (max-width: 500px) {
    font-size: 40px;
  }
`;

export const Subtitle = styled.h2`
  margin: 0;
  font-size: ${fontsizes.subtitle};
  font-weight: ${fontweights.pagesub};

  @media (max-width: 500px) {
    font-size: 23px;
  }
`;

export const Form = styled.form`
  padding: 5vw;
  display: flex;
  position: relative;
  flex-direction: column;
  background: ${colors.white};

  a {
    color: ${colors.orange1};

    &:hover {
      color: ${colors.black};
    }
  }

  p {
    font-size: 16px !important;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    width: 49%;
  }

  @media (max-width: 768px) {
    flex-direction column;

    > div {
      width: 100%;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.marginBottom};
  border: 1px solid red;
  border-style: ${(props) => (props.showErrorBorder ? "solid" : "none")};
`;

export const Label = styled.label`
  font-size: 16px;
  margin-bottom: ${(props) => props.marginBottom};
  color: ${(props) => props.color || colors.black};
`;

export const InputField = styled.input`
  outline: none;
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 25px;
  margin: 10px 0 20px 0;
  border: 1px solid ${colors.black};

  user-select: text !important;
  -webkit-user-select: text !important;
`;

export const SelectField = styled.select`
  height: 34px;
  outline: none;
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 25px;
  margin: 10px 0 20px 0;
  border: 1px solid ${colors.black};

  user-select: text !important;
  -webkit-user-select: text !important;
`;

export const TextAreaField = styled.textarea`
  height: 100px;
  outline: none;
  font-size: 16px;
  resize: vertical;
  padding: 5px 15px;
  border-radius: 25px;
  margin: 10px 0 20px 0;
  border: 1px solid ${colors.black};

  user-select: text !important;
  -webkit-user-select: text !important;
`;

export const InputRadioField = styled.div`
  display: flex;
  margin-bottom: 5px;
  flex-direction: row;
  align-items: center;

  > label {
    font-size: 15px;
  }

  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

export const LanguageSelector = styled.div`
  width: 80px;
  display: flex;
  padding: 10px;
  font-size: 16px;
  flex-direction: row;
  color: ${colors.black};
  justify-content: space-between;

  > div:hover {
    cursor: pointer;
    color: ${colors.orange1};
  }
`;

export const SubmitBtn = styled.button`
  outline: none;
  max-height: 56px;
  margin-right: 20px;
  width: fit-content;
  padding: 15px 80px;
  border-radius: 25px;
  text-decoration: none;
  color: ${colors.black};
  background: ${colors.clear};
  font-size: ${fontsizes.navlink};
  transition: background 0.5s ease;
  border: 2px solid ${colors.black};
  font-weight: ${fontweights.navlinkbold};

  @media (max-width: 768px) {
    padding: 15px 40px;
  }

  &:hover {
    cursor: pointer;
    color: ${colors.white};
    background: ${colors.orange1};
    border-color: ${colors.orange1};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    color: ${colors.black};
    background: ${colors.clear};
    border-color: ${colors.black};
  }
`;

export const Message = styled.div`
  padding: 16px;
  font-size: 16px;
  background: #fff;
  color: ${(props) => props.color};
`;

export const AddBtn = styled.button`
  outline: none;
  max-height: 56px;
  padding: 5px 15px;
  margin-right: 20px;
  width: fit-content;
  border-radius: 25px;
  text-decoration: none;
  color: ${colors.black};
  background: ${colors.clear};
  font-size: ${fontsizes.navlink};
  transition: background 0.5s ease;
  border: 1px solid ${colors.black};
  font-weight: ${fontweights.navlink};

  &:hover {
    cursor: pointer;
    color: ${colors.white};
    background: ${colors.orange1};
    border-color: ${colors.orange1};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    color: ${colors.black};
    background: ${colors.clear};
    border-color: ${colors.black};
  }
`;

export const Loading = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
`;
