/* base */
import React from "react";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useQuery } from "graphql-hooks";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { isInViewport } from "utils/isInViewport";
import { useTitle, useUpdateEffect } from "react-use";
import useLocalStorageState from "use-local-storage-state";

/* assets */
import { colors } from "theme/colors";
import { fontsizes } from "theme/fontsizes";
import { fontweights } from "theme/fontweights";
import SupportImage from "images/support.jpg";

/* components */
import { Module } from "components/module";
import { Footer } from "components/footer";
import {
  Title,
  Section1,
  Subtitle,
  SectionTitle,
  MobileSpacer,
} from "modules/about/styles";
import { LoaderComponent } from "components/loader";
import { ScrollIndicator } from "components/scrollindicator";
import { TitleElement } from "modules/support/components/titleelement";
import { SupportGrid, TextBox, DonateSection } from "modules/support/styles";

const BoxImage = styled.div`
  width: 420px;
  margin: 0 15px;
  border-radius: 10px;
  background: ${colors.bgturquoise2} !important;

  @media (max-width: 768px) {
    margin: 15px 0;
    height: 300px !important;
    width: calc(100% - 35px);
  }

  > div:nth-of-type(1) {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 10px;
    ${(props) =>
      props.bgimg
        ? `
        background-image: url(${props.bgimg});
        background-size: cover;
        background-position: bottom;
        background-repeat: no-repeat;`
        : ""}
  }
`;

const LinkDiv = styled.div`
  display: flex;
  bottom: -115px;
  position: absolute;
  width: 90%;
`;

const StyledNavLink = styled(NavLink)`
  width: fit-content;
  padding: 15px 80px;
  border-radius: 25px;
  text-decoration: none;
  color: ${colors.black};
  background: ${colors.clear};
  font-size: ${fontsizes.navlink};
  transition: background 0.5s ease;
  border: 2px solid ${colors.black};
  font-weight: ${fontweights.navlinkbold};

  @media (max-width: 767px) {
    padding: 15px 40px;
  }

  &:hover {
    color: ${colors.white};
    background: ${colors.bgturquoise1};
    border-color: ${colors.bgturquoise1};
  }
`;

const background1 = `linear-gradient(180deg, ${colors.bgturquoise1} 80%, ${colors.white} 20%);-moz-linear-gradient(180deg, ${colors.bgturquoise1} 80%, ${colors.white} 20%);-o-linear-gradient(180deg, ${colors.bgturquoise1} 80%, ${colors.white} 20%);-webkit-linear-gradient(180deg, ${colors.bgturquoise1} 80%, ${colors.white} 20%);`;

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -200px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const BASE_SUPPORT_QUERY = `query Support($lang: SiteLocale) {
  support {
    title(locale: $lang)
    subtitle(locale: $lang)
    section1TitleSegment1(locale: $lang)
    section1TitleSegment2(locale: $lang)
    section1TextSegment1(locale: $lang)
    section1TextSegment2(locale: $lang)
    section1Image {
      url
    }
    section1Button(locale: $lang)
    section2Title(locale: $lang)
    section2TextSegment1(locale: $lang)
    section2TextSegment2(locale: $lang)
    section2Button(locale: $lang)
  }
}`;

export function SupportModule() {
  useTitle("Support - Make Music Cyprus");

  // const lang = useLocalStorageState("lang", {
  //   defaultValue: "en",
  // })[0];
  const lang = "en";
  const { loading, data, refetch } = useQuery(BASE_SUPPORT_QUERY, {
    variables: { lang: lang || "en" },
  });

  function onScroll() {
    const anchors = document.getElementsByClassName("anchor");
    if (anchors) {
      const element1 = document.getElementById("anchor-1");
      if (element1) {
        const result1 = isInViewport(element1);
        if (result1) {
          anchors[0].classList.add("title-line-2");
        } else {
          anchors[0].classList.remove("title-line-2");
        }
      }
      const element2 = document.getElementById("anchor-2");
      if (element2) {
        const result2 = isInViewport(element2);
        if (result2) {
          anchors[1].classList.add("title-line-1-3");
        } else {
          anchors[1].classList.remove("title-line-1-3");
        }
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useUpdateEffect(() => {
    refetch({
      lang: lang || "en",
    });
  }, [lang]);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Make Music Cyprus - HELP US KEEP THIS FESTIVAL FREE FOR ALL"
        />
      </Helmet>
      <Module background={background1}>
        <Section1>
          <div style={{ transform: "scale(0.8)", margin: "-60px 0 -10px 0" }}>
            <TitleElement color={colors.white} />
          </div>
          <Title>{get(data, "support.title", "SUPPORT")}</Title>
          <Subtitle>
            {get(data, "support.subtitle", "WE COULDN’T DO IT WITHOUT YOU")}
          </Subtitle>
        </Section1>
        <ScrollIndicator color={colors.bgturquoise1} />
      </Module>
      <br />
      <Reveal triggerOnce keyframes={customAnimation}>
        <Module
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="fit-content"
          responsiveHeight="fit-content"
        >
          <div
            id="participate-anim"
            style={{
              left: "0",
              top: "100px",
              position: "absolute",
              transform: "scale(1.5)",
            }}
          >
            <TitleElement color="#ceebeb" />
          </div>
          <SectionTitle>
            <h3 className="anchor" id="anchor-1">
              <b>
                {get(
                  data,
                  "support.section1TitleSegment1",
                  "HELP US KEEP THIS FESTIVAL"
                )}
              </b>
              <br />
              {get(data, "support.section1TitleSegment2", "FREE FOR ALL")}
            </h3>
            <br />
            <br />
          </SectionTitle>
          <SupportGrid>
            <TextBox id="paragraph-text" style={{ gridArea: "text1" }}>
              {get(
                data,
                "support.section1TextSegment1",
                "Make Music Cyprus festival would not happen without the enthusiastic participation of the people, but it also would not be possible without the generous financial support of music lovers everywhere. Since Make Music Cyprus Festival is free for everyone and has no profit, it relies on support from a variety of public and private sources to enable it to fulfil its mission. From commercial and public organisations to foundations and individuals, your help makes the difference in the development and success of the festival."
              )}
            </TextBox>
            <BoxImage
              className="support-image"
              style={{ gridArea: "image" }}
              bgimg={get(data, "support.section1Image.url", SupportImage)}
            >
              <div />
            </BoxImage>
            <TextBox id="paragraph-text" style={{ gridArea: "text2" }}>
              {get(
                data,
                "support.section1TextSegment2",
                "If you would like your organisation or company to become an Official Sponsor of Make Music Cyprus Festival, by supporting it directly or in kind, we will be happy to talk through how that support can be structured and the benefits that your support can attract. Simply let us know of your interest by emailing us at info@makemusiccyprus.org and we will be happy to get in touch."
              )
                .split("info@makemusiccyprus.org")
                .map((item, i) => (
                  <React.Fragment key={item}>
                    {item}
                    {i === 0 && (
                      <a href="mailto:infor@makemusiccyprus.org">
                        <b>info@makemusiccyprus.org</b>
                      </a>
                    )}
                  </React.Fragment>
                ))}
            </TextBox>
            <LinkDiv>
              <StyledNavLink to="/contact">
                {get(data, "support.section1Button", "CONTACT US")}
              </StyledNavLink>
            </LinkDiv>
          </SupportGrid>
          <div style={{ width: "100%", height: 115 }} />
          <MobileSpacer alwaysView />
          <MobileSpacer alwaysView />
        </Module>
      </Reveal>
      <Module
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="fit-content"
        responsiveHeight="fit-content"
      >
        <SectionTitle oppositeLine>
          <h3 className="anchor" id="anchor-2">
            <b>{get(data, "support.section2Title", "DONATIONS")}</b>
          </h3>
          <br />
          <br />
        </SectionTitle>
        <DonateSection>
          <div>
            <p>
              {get(
                data,
                "support.section2TextSegment1",
                "Please consider supporting Make Music Cyprus, a non-profit organization based in Nicosia, by making a contribution today."
              )}
            </p>
            <p>
              {get(
                data,
                "support.section2TextSegment2",
                "Your support will allow Make Music Cyprus to spread the joy of music-making to even more people across the country."
              )}
            </p>
          </div>
          <br />
          <br />
          <StyledNavLink to="/donate">
            {get(data, "support.section2Button", "DONATE NOW")}
          </StyledNavLink>
        </DonateSection>
      </Module>
      <MobileSpacer alwaysView />
      <MobileSpacer alwaysView />
      <Footer />
    </React.Fragment>
  );
}
