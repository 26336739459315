import React from "react";

export const YoutubeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.25638mm"
    height="19.256374mm"
    viewBox="0 0 19.25638 19.256374"
  >
    <g transform="translate(-124.17538,-52.359908)">
      <path
        d="m 133.80375,53.394253 c -4.73887,0 -8.59402,3.855155 -8.59402,8.594019 0,4.738511 3.85515,8.594019 8.59402,8.594019 4.73886,0 8.59366,-3.855508 8.59366,-8.594019 0,-4.738864 -3.8548,-8.594019 -8.59366,-8.594019 z m 0,18.222029 c -5.30931,0 -9.62837,-4.319058 -9.62837,-9.62801 0,-5.309305 4.31906,-9.628364 9.62837,-9.628364 5.30895,0 9.62801,4.319059 9.62801,9.628364 0,5.308952 -4.31906,9.62801 -9.62801,9.62801"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.0352777,
        }}
      />
      <path
        d="m 135.52248,62.1823 -2.55764,1.402997 c -0.11112,0.05997 -0.21131,-0.02046 -0.21131,-0.146759 v -2.879725 c 0,-0.127706 0.10301,-0.208139 0.21449,-0.144992 l 2.57528,1.476731 c 0.11359,0.06456 0.0942,0.229306 -0.0208,0.291748 z m 4.04636,-1.654175 c 0,-1.365603 -1.10737,-2.47262 -2.47297,-2.47262 h -6.41315 c -1.36525,0 -2.47226,1.107017 -2.47226,2.47262 v 3.005666 c 0,1.365603 1.10701,2.472619 2.47226,2.472619 h 6.41315 c 1.3656,0 2.47297,-1.107016 2.47297,-2.472619 v -3.005666"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.0352777,
        }}
      />
    </g>
  </svg>
);
