/* base */
import styled from "styled-components";
import { colors } from "theme/colors";
import { fontsizes } from "theme/fontsizes";
import { fontweights } from "theme/fontweights";

export const InnerModule7 = styled.div`
  padding: 5vh 8vw 15vh 9vw;

  > div:first-of-type {
    > h3 {
      margin: 5px 0;
      text-transform: uppercase;
      font-size: ${fontsizes.subtitle};
      font-weight: ${fontweights.subtitle};
      color: ${(props) => props.textColor || colors.white};

      span {
        font-weight: 100;
        font-family: Open;
        letter-spacing: 8px;
      }
    }

    .anchor {
      position: relative;
      margin-bottom: 24px;

      &:after {
        content: "";
        width: 100vw;
        bottom: -15px;
        position: absolute;
        transform: translateX(-200%);
        transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
        border: 1px solid ${(props) => props.textColor || colors.white};
      }
    }
  }

  @media (max-width: 768px) {
    padding: 5vh;
    overflow-y: hidden;
  }
`;

export const SponsorsGrid = styled.div`
  width: 100%;
  row-gap: 20px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 80px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  h5 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  > div {
    @media (min-width: 768px) {
      height: 171px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    > div {
      gap: 50px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }

  ${(props) =>
    props.bottom
      ? `
          > div {
            > div {
              gap: 40px;
              justify-content: flex-start;

              @media (max-width: 1600px) {
                gap: 20px;
                flex-wrap: wrap;
              }
            }
          }
      `
      : ""}
`;
