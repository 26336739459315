/* base */
import styled from "styled-components";
import { colors } from "theme/colors";
import { fontsizes } from "theme/fontsizes";
import { fontweights } from "theme/fontweights";

export const Button = styled.div`
  width: fit-content;
  // padding: 30px 80px;
  padding: 15px 80px;
  border-radius: 25px;
  text-decoration: none;
  color: ${colors.black};
  background: ${colors.clear};
  font-size: ${fontsizes.navlink};
  transition: background 0.5s ease;
  border: 2px solid ${colors.black};
  font-weight: ${fontweights.navlinkbold};

  @media (max-width: 767px) {
    padding: 15px 40px;
  }

  &:hover {
    cursor: pointer;
    color: ${colors.white};
    background: ${colors.bggrey1};
    border-color: ${colors.bggrey1};
  }
`;

export const FooterContainer = styled.div`
  > footer {
    border-top-color: transparent !important;
  }
`;
