/* base */
import styled from "styled-components";
import { NavLink } from "react-router-dom";

/* assets */
import { colors } from "theme/colors";
import { fontsizes } from "theme/fontsizes";
import { fontweights } from "theme/fontweights";

export const SignupDiv = styled.div`
  @media (max-width: 767px) {
    margin: 0;
    width: 100%;
    padding: 30px 0;
    text-align: center;
    border-bottom: 1px solid ${colors.white};
  }
`;

export const InvertedStyledNavLink = styled(NavLink)`
  padding: 7px 10px;
  text-decoration: none;
  color: ${colors.white};
  font-size: ${fontsizes.navlink};
  transition: background 0.5s ease;
  font-weight: ${fontweights.navlink};

  > span {
    pointer: none;
    margin: 0 1px;
    font-weight: 900;
    font-family: Open;
    display: inline-block;
    transition: transform 0.7s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }
`;
