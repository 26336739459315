/* base */
import React from "react";

/* components */
import { Module } from "components/module";
import { background1 } from "modules/home/data";
import { Slideshow } from "modules/home/components/slideshow";
import { Soundwaves } from "modules/home/components/soundwaves";
import { Title, TitleContainer } from "modules/home/modules/module1/styles";

export const HomeModule1 = (props) => (
  <Module
    display="flex"
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
    background={background1}
  >
    <Slideshow />
    <TitleContainer>
      <Title>{props.title1}</Title>
      <Title>{props.title2}</Title>
    </TitleContainer>
    <Soundwaves />
  </Module>
);
