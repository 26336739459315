import React from "react";

export const Chevron = (props) => (
  <svg height="24" viewBox="0 0 24 24" width="24" style={props.style}>
    <path
      fill={props.fill || "#fff"}
      d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z"
    />
  </svg>
);
