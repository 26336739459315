import React from "react";
import {
  Container,
  SuperContainer,
} from "modules/about/components/titleelement/styles";

const commonstyle = {
  transform: "scale(0.3)",
};

export const TitleElement = (props) => (
  <SuperContainer>
    <div style={commonstyle}>
      <Container color={props.color}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </Container>
    </div>
    <div style={{ width: "53px" }}></div>
    <div style={commonstyle}>
      <Container opposite color={props.color}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </Container>
    </div>
  </SuperContainer>
);
