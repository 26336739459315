import React from "react";
import styled from "styled-components";
import { Module } from "components/module";

// assets
import { ReactComponent as Header } from "./assets/header.svg";
import { ReactComponent as Ermou } from "./assets/ermou.svg";
import { ReactComponent as NM } from "./assets/nm.svg";
import { ReactComponent as Faneromeni } from "./assets/faneromenis.svg";
import { ReactComponent as FaneromeniStreet } from "./assets/faneromenistreet.svg";
import { ReactComponent as OnasagorouStreet } from "./assets/onasagoroustreet.svg";
import { ReactComponent as LedraArcade } from "./assets/ledrarcade.svg";
import { ReactComponent as LedraArsinois } from "./assets/ledrarsinois.svg";
import { ReactComponent as LedraOnasagorou } from "./assets/ledraonasagorou.svg";
import { ReactComponent as EleftheriaBridge } from "./assets/eleftheriasquarebridge.svg";
import { ReactComponent as Solomou } from "./assets/solomou.svg";
import { ReactComponent as EleftheriaMoat1 } from "./assets/eleftheriasquaremoat1.svg";
import { ReactComponent as EleftheriaMoat2 } from "./assets/eleftheriasquaremoat2.svg";
import { ReactComponent as EleftheriaMoat3 } from "./assets/eleftheriasquaremoat3.svg";
import { ReactComponent as EleftheriaMoat4 } from "./assets/eleftheriasquaremoat4.svg";
import { ReactComponent as EleftheriaMoat5 } from "./assets/eleftheriasquaremoat5.svg";
import { ReactComponent as ZenaPalace } from "./assets/zenapalace.svg";
import { ReactComponent as Makariou } from "./assets/360.svg";

const Container = styled.div`
  width: 80%;
  min-height: calc(100vh - 120px);

  @media (max-width: 768px) {
    width: 90vw;
  }
`;

export default function Programme() {
  return (
    <Module
      display="flex"
      alignItems="center"
      padding="120px 0 0 0;"
      flexDirection="column"
      justifyContent="center"
      height="fit-content"
      responsiveHeight="fit-content"
      style={{
        backgroundImage: `linear-gradient(180deg, rgb(249, 195, 61) 0%, rgb(236, 158, 56) 12.97%, rgb(203, 63, 45) 41.74%, rgb(193, 34, 41) 50.38%, rgb(86, 45, 128) 96.03%)`,
      }}
    >
      <Container>
        <br />
        <br />
        <Header />
        <Ermou />
        <NM />
        <Faneromeni />
        <OnasagorouStreet />
        <FaneromeniStreet />
        <LedraArcade />
        <LedraArsinois />
        <LedraOnasagorou />
        <EleftheriaBridge />
        <Solomou />
        <EleftheriaMoat1 />
        <EleftheriaMoat2 />
        <EleftheriaMoat3 />
        <EleftheriaMoat4 />
        <EleftheriaMoat5 />
        <ZenaPalace />
        <Makariou />
        <br />
        <br />
      </Container>
    </Module>
  );
}
