import React from "react";
import styled from "styled-components";
import { colors } from "theme/colors";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  opacity: 0.1;
  margin: 0 5px;
  border-left: 25px solid ${colors.bgred2};
  border-top: 15px solid transparent;
  border-bottom: 12px solid transparent;
  animation: leadanimation 1.5s infinite linear;

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.3s;
  }
  &:nth-child(3) {
    animation-delay: 0.6s;
  }
`;

export const LeadIcon = () => (
  <Container>
    <Triangle />
    <Triangle />
    <Triangle />
  </Container>
);
