/* base */
import styled from "styled-components";

export const InnerModule6 = styled.div`
  padding: 13vh 8vw 15vh 9vw;

  @media (max-width: 600px) {
    max-height: 50vh;
    overflow-y: hidden;
    margin-bottom: 15vh;
    padding: 13vh 5vw 0 5vw;
  }
`;
