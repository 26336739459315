import React from "react";
import { useHistory } from "react-router-dom";

function sendPageView(location) {
  window.gtag("event", "page_view", {
    page_title: document.title,
    page_location: window.location.href,
    page_path: location.pathname,
    send_to: "G-CZ6HRZ3PW2",
  });
}

export function useGA() {
  const history = useHistory();

  React.useEffect(() => {
    if (window.location.hostname === "makemusiccyprus.org") {
      return history.listen(sendPageView);
    }
  }, [history]);

  return null;
}
