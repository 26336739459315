/* base */
import React from "react";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import { useQuery } from "graphql-hooks";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { isInViewport } from "utils/isInViewport";
import useLocalStorageState from "use-local-storage-state";
import { useTitle, useHoverDirty, useUpdateEffect } from "react-use";

/* assets */
import { colors } from "theme/colors";
import VideoBGSVG from "icons/AboutUs_Background.svg";
import AboutImage from "images/about.jpg";

/* components */
import { LoaderComponent } from "components/loader";
import { Module } from "components/module";
import {
  Box,
  Boxes,
  Title,
  BoxImage,
  Section1,
  Subtitle,
  SectionTitle,
  MobileSpacer,
  Section2Boxes,
} from "modules/about/styles";
import { Soundwaves } from "modules/about/components/soundwaves";
import {
  VideoBG,
  InnerModule3,
  Module3PaddingTop,
} from "modules/home/modules/module3/styles";
import { TitleElement } from "./components/titleelement";
import { SideSoundwaves } from "./components/sidesoundwaves";
import { Footer } from "components/footer";
import { Chevron } from "icons/Chevron";
import { Section2, Section2Up, Section2Text } from "modules/participate/styles";
import { ScrollIndicator } from "components/scrollindicator";

const background1 = `linear-gradient(180deg, ${colors.bgblue1} 80%, ${colors.white} 20%);-moz-linear-gradient(180deg, ${colors.bgblue1} 80%, ${colors.white} 20%);-o-linear-gradient(180deg, ${colors.bgblue1} 80%, ${colors.white} 20%);-webkit-linear-gradient(180deg, ${colors.bgblue1} 80%, ${colors.white} 20%);`;

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -200px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const vline = <span style={{ fontWeight: 800, opacity: 0.3 }}>|</span>;

const hoverArrow = (
  <div
    style={{
      right: 20,
      bottom: 20,
      position: "absolute",
    }}
  >
    <Chevron />
  </div>
);

const BASE_ABOUT_QUERY = `query About($lang: SiteLocale) {
  about {
    title(locale: $lang)
    subtitle(locale: $lang)
    section2TextSegment1(locale: $lang)
    section2TextSegment2(locale: $lang)
    section2TextSegment3(locale: $lang)
    section3TitleSegment1(locale: $lang)
    section3TitleSegment2(locale: $lang)
    section3SubtitleSegment1(locale: $lang)
    section3SubtitleSegment2(locale: $lang)
    section3Box1Title(locale: $lang)
    section3Box1Subtitle(locale: $lang)
    section3Box1Text(locale: $lang)
    section3Box1Items(locale: $lang)
    section3Box2Title(locale: $lang)
    section3Box2Subtitle(locale: $lang)
    section3Box2Text(locale: $lang)
    section3Box2Items(locale: $lang)
    section3Box3Title(locale: $lang)
    section3Box3Subtitle(locale: $lang)
    section3Box3Text(locale: $lang)
    section3Box3Items(locale: $lang)
    section4Video {
      providerUid
    }
    section5TitleSegment1(locale: $lang)
    section5TitleSegment2(locale: $lang)
    section5Text(locale: $lang)
    section5Image {
      url
    }
    section6TitleSegment1(locale: $lang)
    section6TitleSegment2(locale: $lang)
    section6Subtitle(locale: $lang)
    section6TextSegment1(locale: $lang)
    section6TextSegment2(locale: $lang)
  }
}`;

export function AboutModule() {
  useTitle("About - Make Music Cyprus");

  const hoverContainer1 = React.useRef();
  const isHovering1 = useHoverDirty(hoverContainer1);
  const hoverContainer2 = React.useRef();
  const isHovering2 = useHoverDirty(hoverContainer2);
  const hoverContainer3 = React.useRef();
  const isHovering3 = useHoverDirty(hoverContainer3);

  // const lang = useLocalStorageState("lang", {
  //   defaultValue: "en",
  // })[0];
  const lang = "en";
  const { loading, data, refetch } = useQuery(BASE_ABOUT_QUERY, {
    variables: { lang: lang || "en" },
  });

  function onScroll() {
    const anchors = document.getElementsByClassName("anchor");
    if (anchors) {
      const element1 = document.getElementById("anchor-1");
      if (element1) {
        const result1 = isInViewport(element1);
        if (result1) {
          anchors[0].classList.add("title-line-2");
        } else {
          anchors[0].classList.remove("title-line-2");
        }
      }
      const element2 = document.getElementById("anchor-2");
      if (element2) {
        const result2 = isInViewport(element2);
        if (result2) {
          anchors[1].classList.add("title-line-1-3");
        } else {
          anchors[1].classList.remove("title-line-1-3");
        }
      }
      const element3 = document.getElementById("anchor-3");
      if (element3) {
        const result3 = isInViewport(element3);
        if (result3) {
          anchors[2].classList.add("title-line-2");
        } else {
          anchors[2].classList.remove("title-line-2");
        }
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useUpdateEffect(() => {
    refetch({
      lang: lang || "en",
    });
  }, [lang]);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Make Music Cyprus fills within the walls Nicosia’s outdoor spaces and venues with hundreds of live concerts and music activities, all free and open to the public."
        />
      </Helmet>
      <Module background={background1}>
        <Section1>
          <TitleElement color={colors.white} />
          <Title>{get(data, "about.title", "ABOUT")}</Title>
          <Subtitle>
            {get(data, "about.subtitle", "A COMPLETELY DIFFERENT FESTIVAL")}
          </Subtitle>
        </Section1>
        <ScrollIndicator color={colors.bgblue1} />
      </Module>
      <Module height="fit-content" responsiveHeight="fit-content">
        <Section2
          style={{
            height: "50vh",
            position: "relative",
            justifyContent: "center",
            background: colors.bgwhite,
          }}
        >
          <Section2Up style={{ height: "fit-content" }}>
            <Section2Text
              style={{ color: colors.black, textTransform: "uppercase" }}
              noSmbr
            >
              {
                get(
                  data,
                  "about.section2TextSegment1",
                  "MAKE MUSIC CYPRUS, IN COLLABORATION WITH ARTIO,"
                ).split("ARTIO")[0]
              }{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.artiomusic.org/"
              >
                <b>ARTio</b>
              </a>
              , <br />
              {get(
                data,
                "about.section2TextSegment2",
                "joined Make Music Day in 2019, presenting the first"
              )}
              <br />
              {get(
                data,
                "about.section2TextSegment3",
                "Make Music Festival in Cyprus in the old city of Nicosia."
              )}
            </Section2Text>
          </Section2Up>
          <div
            id="participate-anim"
            style={{
              bottom: -102,
              width: "100%",
              right: "-40vw",
              position: "absolute",
              transform: "scale(1.5)",
            }}
          >
            <TitleElement color={colors.white} />
          </div>
        </Section2>
        <MobileSpacer alwaysView />
        <MobileSpacer alwaysView />
      </Module>
      <Reveal triggerOnce keyframes={customAnimation}>
        <Module
          responsiveHeight="fit-content"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <SectionTitle>
            <h3 className="anchor" id="anchor-1">
              <b>
                {get(data, "about.section3TitleSegment1", "A WORLDWIDE MUSIC")}
              </b>
              <br />
              {get(data, "about.section3TitleSegment2", "FESTIVAL IN NICOSIA")}
            </h3>
            <br />
            <h4>
              {get(
                data,
                "about.section3SubtitleSegment1",
                "Make music is a celebration of music"
              )}
              <br />
              {get(
                data,
                "about.section3SubtitleSegment2",
                "around the world on June 21st of every year"
              )}
            </h4>
          </SectionTitle>
          <Section2Boxes>
            <div className="section-2-box" ref={hoverContainer1}>
              {!isHovering1 ? (
                <React.Fragment>
                  <h6>{get(data, "about.section3Box1Title", "MUSIC")}</h6>
                  <div>
                    {get(
                      data,
                      "about.section3Box1Subtitle",
                      "Anything and everything!"
                    )}
                    <br />
                    <br />
                    {get(
                      data,
                      "about.section3Box1Text",
                      "Listen to your favorites and discover something new."
                    )}
                  </div>
                  {hoverArrow}
                </React.Fragment>
              ) : (
                <div className="paragraph-container">
                  <p>
                    {get(
                      data,
                      "about.section3Box1Items",
                      "Classical | Folk | Traditional | Jazz | Latin | Rock | Punk | Hip Hop | RnB | Electronic | Experimental"
                    )
                      .split(" | ")
                      .map((i) => (
                        <React.Fragment key={i}>
                          {i} {vline}{" "}
                        </React.Fragment>
                      ))}
                  </p>
                </div>
              )}
            </div>
            <div className="section-2-box" ref={hoverContainer2}>
              {!isHovering2 ? (
                <React.Fragment>
                  <h6>{get(data, "about.section3Box2Title", "HOW")}</h6>
                  <div>
                    {get(
                      data,
                      "about.section3Box1Subtitle",
                      "Music of all shapes and sizes!"
                    )}
                    <br />
                    <br />
                    {get(
                      data,
                      "about.section3Box1Text",
                      "Listen to and take part in unique musical experiences."
                    )}
                  </div>
                  {hoverArrow}
                </React.Fragment>
              ) : (
                <div className="paragraph-container">
                  <p>
                    {get(
                      data,
                      "about.section3Box2Items",
                      "Interactive Music Happenings | Educational Music Activities | Music Lessons | Choirs | Orchestras | Chamber Ensembles | Bands | Individuals"
                    )
                      .split(" | ")
                      .map((i) => (
                        <React.Fragment key={i}>
                          {i} {vline}{" "}
                        </React.Fragment>
                      ))}
                  </p>
                </div>
              )}
            </div>
            <div className="section-2-box" ref={hoverContainer3}>
              {!isHovering3 ? (
                <React.Fragment>
                  <h6>{get(data, "about.section3Box3Title", "WHERE")}</h6>
                  <div>
                    {get(
                      data,
                      "about.section3Box1Subtitle",
                      "Anywhere and everywhere!"
                    )}
                    <br />
                    <br />
                    {get(
                      data,
                      "about.section3Box1Text",
                      "Music can be found in the most unexpected locations."
                    )}
                  </div>
                  {hoverArrow}
                </React.Fragment>
              ) : (
                <div className="paragraph-container">
                  <p>
                    {get(
                      data,
                      "about.section3Box3Items",
                      "Streets | Sidewalks | Porches | Plazas | Parks | Gardens"
                    )
                      .split(" | ")
                      .map((i) => (
                        <React.Fragment key={i}>
                          {i} {vline}{" "}
                        </React.Fragment>
                      ))}
                  </p>
                </div>
              )}
            </div>
            <Soundwaves />
          </Section2Boxes>
          <MobileSpacer alwaysView />
        </Module>
      </Reveal>
      <Module background={colors.bgblue2} zIndex={2} responsiveHeight="35vh">
        <InnerModule3>
          <VideoBG opacity={0.5} url={VideoBGSVG} />
          <Module3PaddingTop />
          <iframe
            title="video"
            allowFullScreen
            frameBorder="0"
            src={`https://www.youtube.com/embed/${get(
              data,
              "about.section4Video.providerUid",
              "xaRdPL8qVE0"
            )}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </InnerModule3>
      </Module>
      <Reveal triggerOnce keyframes={customAnimation}>
        <Module
          display="flex"
          alignItems="center"
          smHeight="fit-content"
          flexDirection="column"
          justifyContent="center"
          background={colors.white}
        >
          <MobileSpacer />
          <SectionTitle oppositeLine>
            <h3 className="anchor" id="anchor-2">
              <b>{get(data, "about.section5TitleSegment1", "MAKE MUSIC")}</b>
              <br />
              {get(data, "about.section5TitleSegment2", "NICOSIA JUNE 21")}
            </h3>
            <br />
          </SectionTitle>
          <Boxes withImg>
            <Box id="paragraph-text" noCol style={{ height: "fit-content" }}>
              {get(
                data,
                "about.section5Text",
                "Make Music Cyprus celebrates the musician in all of us, connects citizens to each other, unites diverse communities, brings new artistic creations to life, activates a spirit of celebration and free creation without boundaries, creates an experience that is unique to the ecosystem of each city, energizes social spaces, makes Cyprus a multicultural country, connects Cyprus worldwide."
              )}
            </Box>
            <BoxImage
              smHeight="277px"
              bgimg={get(data, "about.section5Image.url", AboutImage)}
            >
              <SideSoundwaves
                color={colors.sidesoundwaves}
                position="right: -43%;bottom: 60%;"
                responsivePosition="right: -35%;bottom: 35%;"
              />
              <div />
            </BoxImage>
          </Boxes>
          <MobileSpacer />
        </Module>
      </Reveal>
      <Module
        display="flex"
        alignItems="center"
        smHeight="fit-content"
        flexDirection="column"
        justifyContent="center"
        background={colors.bgwhite}
      >
        <MobileSpacer />
        <SectionTitle>
          <h3 className="anchor" id="anchor-3">
            <b>{get(data, "about.section6TitleSegment1", "HISTORY")}</b>
            <br />
            {get(data, "about.section6TitleSegment2", "OF MAKE MUSIC")}
          </h3>
          <br />
          <h4>
            {get(data, "about.section6Subtitle", "An international phenomenon")}
          </h4>
        </SectionTitle>
        <Boxes noCol big>
          <Box id="paragraph-text">
            {get(
              data,
              "about.section6TextSegment1",
              "Make Music Day began in Paris in 1982 as Fête de la Musique (In French, the name means both “festival of music” and “make music!”). France’s Ministry of Culture dreamed up an idea for a new kind of musical holiday. They imagined a day where free, live music would be everywhere, and unlike a typical music festival, anyone and everyone would be invited to join and play music or host performances. This celebration of music has turned into a true national holiday: France shuts down on the Summer Solstice and musicians take over."
            )}
          </Box>
          <Box id="paragraph-text">
            {get(
              data,
              "about.section6TextSegment2",
              "Almost 8% of the country (5 million people) have played an instrument or sung in public for the Fête de la Musique. It all started 37 years ago in France. Nicosia joined Make Music Day for the first time in 2019 and is one of the 1000 cities that are part of this worldwide music celebration, with each city creating an experience that is unique to their ecosystem. Make Music Day has spread throughout the world and has become an international phenomenon, taking place on the Summer Solstice, the longest day of each year."
            )}
          </Box>
          <SideSoundwaves color="#84B6C1" position="left: -48%;bottom: 60%;" />
        </Boxes>
      </Module>
      <MobileSpacer color={colors.bgwhite} alwaysView />
      <Footer style={{ borderTopColor: colors.bgwhite }} />
    </React.Fragment>
  );
}
