/* base */
import styled from "styled-components";
import { misc } from "theme/misc";
import { colors } from "theme/colors";
import { fontsizes } from "theme/fontsizes";
import { fontweights } from "theme/fontweights";
import HomeBig2Image from "images/homebig2.jpg";

export const InnerModule4 = styled.div`
  height: 100%;
  display: flex;
  padding: 0 10vw;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: row;

  @media (max-width: 768px) {
    min-height: 100vh;
    height: fit-content;
    justify-content: center;
    flex-direction: column-reverse;
  }

  > div:first-of-type {
    width: 40%;
    height: 80%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: ${misc.boxshadow};
    background-image: url(${(props) => props.imageUrl ?? HomeBig2Image});

    @media (max-width: 768px) {
      width: 100%;
      height: 22vh;
      margin-bottom: calc(35vh * 17.5 / 100);
      margin-top: calc(35vh * 17.5 / 100 + 25px);
    }

    > div {
      left: -10vw;
      z-index: -1;
      bottom: -10vw;
      position: absolute;
      background: #f05a23;
      height: calc(33.3333%);
      width: calc(100% + 10vw);
    }
  }

  > div:nth-of-type(2) {
    width: 60%;
    height: 100%;
    display: flex;
    padding-left: 100px;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
      width: 100%;
      padding-left: 0;
      margin-top: calc(35vh * 17.5 / 100 - 23px);
    }

    > div {
      @media (max-width: 768px) {
        width: 100%;
      }

      > h2 {
        margin: 5px 0;
        text-transform: uppercase;
        font-size: ${fontsizes.subtitle};
        font-weight: ${fontweights.subtitle};

        span {
          font-weight: 100;
          font-family: Open;
          letter-spacing: 8px;
        }
      }

      .anchor {
        position: relative;

        &:before {
          content: "";
          width: 100vw;
          bottom: -15px;
          position: absolute;
          border: 1px solid ${colors.black};
          transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
          // transform: translateX(${(props) =>
            props.showLine ? "" : "200%"});
          transform: translateX(200%);
        }
      }

      > p {
        width: 60%;
        text-align: start;
        font-size: ${fontsizes.text};
      }

      > a {
        width: fit-content;
        // padding: 30px 80px;
        padding: 15px 80px;
        border-radius: 25px;
        text-decoration: none;
        color: ${colors.black};
        background: ${colors.clear};
        font-size: ${fontsizes.navlink};
        transition: background 0.5s ease;
        border: 2px solid ${colors.black};
        font-weight: ${fontweights.navlinkbold};

        @media (max-width: 768px) {
          padding: 15px 40px;
        }

        &:hover {
          color: ${colors.white};
          background: ${colors.orange1};
          border-color: ${colors.orange1};
        }
      }
    }

    > div:nth-of-type(2) {
      display: flex;
      align-items: flex-start;

      > a {
        width: fit-content;
        padding: 30px 80px;
        border-radius: 25px;
        text-decoration: none;
        color: ${colors.black};
        background: ${colors.clear};
        font-size: ${fontsizes.navlink};
        transition: background 0.5s ease;
        border: 2px solid ${colors.black};
        font-weight: ${fontweights.navlinkbold};

        @media (max-width: 768px) {
          padding: 15px 40px;
        }

        &:hover {
          color: ${colors.white};
          background: ${colors.orange1};
          border-color: ${colors.orange1};
        }
      }
    }
  }
`;
