export const fontsizes = {
  navlink: "1em",
  menubutton: "1.125em",
  title: "6.5em",
  detailpagetitle: "6em",
  bgBigText: "5em",
  subtitle: "3em",
  text: "1.125em",
  h3: "2.250em",
  h4: "1.750em",
  h6: "1.250em",
  footer: "1em",
  wordcloud1: "3em",
  wordcloud2: "3.175em",
};
