import React from "react";
import { colors } from "theme/colors";
import { Module } from "components/module";

export function LiveStream() {
  return (
    <Module
      display="flex"
      alignItems="center"
      padding="120px 0 0 0;"
      flexDirection="column"
      justifyContent="center"
      height="fit-content"
      responsiveHeight="fit-content"
      background={colors.orange1}
    >
      <div
        style={{
          width: "80%",
          minHeight: "calc(100vh - 120px)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <br />
        <br />
        <iframe
          class="code"
          scrolling="no"
          frameborder="0"
          title="Live Stream"
          src="https://volume.com/in/?tour=7atp&campaign=HCkPq&track=embed&room=makemusicday&disable_sound=1&mobileRedirect=auto&embed_video_only=1"
          style={{
            top: 0,
            left: 0,
            width: "100%",
            height: "95%",
            position: "absolute",
          }}
        />
        <br />
        <br />
      </div>
    </Module>
  );
}
