import React from "react";

export const FacebookIcon = () => (
  <svg width="19.25638mm" height="19.256374mm" viewBox="0 0 19.25638 19.256374">
    <g transform="translate(-130.223,89.759139)">
      <path
        d="m 139.85137,-88.724795 c -4.73922,0 -8.59437,3.855508 -8.59437,8.594019 0,4.738512 3.85515,8.59402 8.59437,8.59402 4.73816,0 8.59366,-3.855508 8.59366,-8.59402 0,-4.738511 -3.8555,-8.594019 -8.59366,-8.594019 z m 0,18.22203 c -5.30931,0 -9.62837,-4.319058 -9.62837,-9.628011 0,-5.308952 4.31906,-9.628363 9.62837,-9.628363 5.30895,0 9.62801,4.319411 9.62801,9.628363 0,5.308953 -4.31906,9.628011 -9.62801,9.628011"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.0352777,
        }}
      />
      <path
        d="m 138.34113,-80.085267 v 5.580239 c 0,0.08043 0.0653,0.145344 0.14569,0.145344 h 2.07222 c 0.0801,0 0.14534,-0.06491 0.14534,-0.145344 v -5.671256 h 1.50213 c 0.0755,0 0.13829,-0.05786 0.14499,-0.132997 l 0.14429,-1.708856 c 0.007,-0.08467 -0.0596,-0.157691 -0.14464,-0.157691 h -1.64677 v -1.212145 c 0,-0.284339 0.23001,-0.514703 0.51435,-0.514703 h 1.15782 c 0.0801,0 0.14534,-0.06491 0.14534,-0.144991 v -1.708856 c 0,-0.08043 -0.0653,-0.145344 -0.14534,-0.145344 h -1.95651 c -1.14829,0 -2.07891,0.930628 -2.07891,2.078566 v 1.647473 h -1.03611 c -0.0804,0 -0.14535,0.06526 -0.14535,0.145344 v 1.708855 c 0,0.08043 0.0649,0.145345 0.14535,0.145345 h 1.03611 v 0.09102"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
          strokeWidth: 0.0352777,
        }}
      />
    </g>
  </svg>
);
