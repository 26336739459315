import { colors } from "theme/colors";
import { keyframes } from "@emotion/react";

export const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -200px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
export const background1 = `linear-gradient(180deg, ${colors.orange1} 80%, ${colors.white} 20%);-moz-linear-gradient(180deg, ${colors.orange1} 80%, ${colors.white} 20%);-o-linear-gradient(180deg, ${colors.orange1} 80%, ${colors.white} 20%);-webkit-linear-gradient(180deg, ${colors.orange1} 80%, ${colors.white} 20%);`;
export const background5 = `${colors.bgyellow};`;
export const background6 = `linear-gradient(180deg, ${colors.bgyellow} 40%, ${colors.orange1} 0%);-moz-linear-gradient(180deg, ${colors.bgyellow} 40%, ${colors.orange1} 0%);-o-linear-gradient(180deg, ${colors.bgyellow} 40%, ${colors.orange1} 0%);-webkit-linear-gradient(180deg, ${colors.bgyellow} 40%, ${colors.orange1} 0%);`;
export const background7 = `${colors.orange1}`;
