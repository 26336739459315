import styled from "styled-components";
import { colors } from "theme/colors";
import { fontsizes } from "theme/fontsizes";
import { fontweights } from "theme/fontweights";

export const Section2 = styled.div`
  display: flex;
  padding: 0 10vw;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 0 5vw;
  }

  @media (max-width: 600px) {
    #participate-anim {
      display: none;
    }
  }
`;

export const Section2Up = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  > h3 {
    color: ${colors.black};
  }
`;

export const Section2Down = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const Section2Text = styled.h3`
  margin: 0;
  line-height: 1.5em;
  color: ${colors.white};
  font-size: ${fontsizes.h3};
  font-weight: ${fontweights.pagesub};

  a {
    color: ${colors.black};

    &:hover {
      color: ${colors.orange1};
    }
  }

  ${(props) =>
    props.noSmbr
      ? `
    @media (max-width: 600px) {
      > br {
        display: none;
      }
    }
  `
      : ""}

  @media (max-width: 500px) {
    font-size: 23px;
  }
`;

export const Section3 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Section3Up = styled.div`
  width: 100%;
  height: 20vh;
  background: ${colors.bggrey1};
`;

export const Section3Down = styled.div`
  height: 100%;
  display: grid;
  grid-gap: 48px;
  padding: 10vh 10vw;
  background: ${colors.white};
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 767px) {
    padding: 5vh 5vw;
    grid-template-columns: 1fr;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const SoundwavesIcon = styled.div`
  right: 34px;
  padding-top: 26px;
  position: absolute;
  transform: scale(0.8);

  > div {
    margin: 0;

    span {
      background: ${colors.bgred2} !important;
    }
  }
`;

export const Section4 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    height: clear;
    #participate-anim {
      display: none;
    }
  }
`;

export const Section4Up = styled.div`
  // width: 100%;
  height: 20%;
  padding: 40px 10vw 0 10vw;
  background: ${colors.white};

  > h3 {
    color: ${colors.black};
  }

  @media (max-width: 767px) {
    height: 30%;
    padding: 40px 5vw 5vw 5vw;
  }
`;

export const Section4Down = styled.div`
  height: 80%;
  padding: 20px 10vw;
  position: relative;
  background: ${colors.bgred1};

  .anchor {
    position: relative;
    margin-bottom: 24px;

    &:after {
      content: "";
      width: 100vw;
      bottom: -15px;
      position: absolute;
      transform: translateX(-200%);
      border: 1px solid ${colors.white};
      transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
    }
  }

  @media (max-width: 767px) {
    // height: 70%;
    padding: 20px 5vw;
  }

  > a {
    width: fit-content;
    padding: 15px 80px;
    border-radius: 25px;
    text-decoration: none;
    color: ${colors.white};
    background: ${colors.clear};
    font-size: ${fontsizes.navlink};
    transition: background 0.5s ease;
    border: 2px solid ${colors.white};
    font-weight: ${fontweights.navlinkbold};

    @media (max-width: 767px) {
      padding: 15px 40px;
    }

    &:hover {
      color: ${colors.black};
      background: ${colors.white};
      border-color: ${colors.white};
    }
  }
`;
