import React from "react";
import { Container, Item } from "./styles";
import Image1 from "images/homeminigallery1.jpg";
import Image2 from "images/homeslide1.jpg";
import Image3 from "images/homeminigallery3.jpg";
import Image4 from "images/homeminigallery4.jpg";
import Image5 from "images/homeslide2.jpg";
import Image6 from "images/homeminigallery6.jpg";

const images = [Image1, Image2, Image3, Image4, Image5, Image6];

export function ImagesGrid(props) {
  return (
    <Container>
      {(props.images ?? images).map((image) => (
        <Item key={image} src={image} />
      ))}
    </Container>
  );
}
