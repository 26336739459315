import React from "react";
import styled from "styled-components";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import Image1 from "images/homeslide1.jpg";
import Image2 from "images/homeslide2.jpg";
import Image3 from "images/homebig2.jpg";

const BackgroundOpacity = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: #000;
  position: absolute;
`;

const lazyLoadImages = () => {
  const lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
  if ("IntersectionObserver" in window) {
    let lazyImageObserver = new IntersectionObserver(function (
      entries,
      observer
    ) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          let lazyImage = entry.target;
          lazyImage.src = lazyImage.dataset.src;
          lazyImage.classList.remove("lazy");
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyImages.forEach(function (lazyImage) {
      lazyImageObserver.observe(lazyImage);
    });
  }
};

export function Slideshow() {
  const properties = {
    duration: 5000,
    autoplay: true,
    infinite: true,
    arrows: false,
  };
  const images = [Image1, Image2, Image3];

  React.useEffect(() => lazyLoadImages(), []);

  return (
    <div className="slide-container">
      <BackgroundOpacity />
      <Slide {...properties}>
        {images.map((image) => (
          <div key={image} className="each-slide">
            <img
              className="lazy"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              data-src={image}
              alt=""
            />
          </div>
        ))}
      </Slide>
    </div>
  );
}
