/* base */
import React from "react";

/* components */
import { Module } from "components/module";
import { background6 } from "modules/home/data";
import { Wordcloud } from "components/wordcloud";
import { InnerModule6 } from "modules/home/modules/module6/styles";

export const HomeModule6 = () => (
  <Module background={background6} height="fit-content">
    <InnerModule6>
      <Wordcloud />
    </InnerModule6>
  </Module>
);
