import React from "react";
import styled from "styled-components";
import { colors } from "theme/colors";

const size = "50px";
const circleColor = colors.bgred2;

const Container = styled.div`
  display: none;
  padding: 26px 26px 0 0;

  [foo],
  & {
    top: -90px;
    right: -74px;
    width: ${size};
    display: block;
    height: ${size};
    position: absolute;
    display: inline-block;
    transform: rotate(-45deg) translate(-100px) scale(0.7);
  }
  .wifi-circle {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    font-size: ${size} / 7;
    position: absolute;
    bottom: 0;
    left: 0;
    border-color: ${circleColor};
    border-style: solid;
    border-width: 1em 1em 0 0;
    -webkit-border-radius: 0 100% 0 0;
    border-radius: 0 100% 0 0;

    opacity: 0;
    animation: wifianimation 1.5s infinite linear;

    &.first {
      animation-delay: 800ms;
    }
    &.second {
      width: 5em;
      height: 5em;
      animation-delay: 400ms;
    }
    &.third {
      width: 3em;
      height: 3em;
    }
    &.fourth {
      width: 1em;
      height: 1em;
      opacity: 1;
      background-color: ${circleColor};
      animation: none;
    }
  }
`;

export const SignalIcon = () => (
  <Container>
    <div className="wifi-circle second" />
    <div className="wifi-circle third" />
    <div className="wifi-circle fourth" />
  </Container>
);
