/* base */
import React from "react";
import Reveal from "react-awesome-reveal";
import { NavLink } from "react-router-dom";

/* assets */
import { colors } from "theme/colors";

/* components */
import { Module } from "components/module";
import { customAnimation } from "modules/home/data";
import { PGrid } from "modules/home/modules/module2/styles";
import { InnerModule4 } from "modules/home/modules/module4/styles";

export const HomeModule4 = (props) => (
  <Reveal triggerOnce keyframes={customAnimation}>
    <Module
      zIndex={1}
      padding="30px 0;"
      smHeight="fit-content"
      background={colors.white}
      responsiveMinHeight="100vh"
    >
      <InnerModule4 imageUrl={props.imageURL}>
        <div />
        <div>
          <div>
            <h2>
              <b>{props.title1}</b>
            </h2>
            <h2>{props.title2}</h2>
            <h2 id="2-third-h2" className="anchor">
              {props.title3}
            </h2>
            <br />
            <br />
            <PGrid>
              <p>{props.content1}</p>
              <p>
                {props.content2}
                <br />
                <br />
                <b>{props.slogan}</b>
              </p>
            </PGrid>
            <br />
            <br />
            <br />
            <br />
            <NavLink to="/participate">{props.button}</NavLink>
          </div>
        </div>
      </InnerModule4>
    </Module>
  </Reveal>
);
