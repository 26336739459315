/* base */
import styled from "styled-components";

export const SuperContainer = styled.div`
  z-index: 0;
  width: 100%;
  height: auto;
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
  ${(props) => props.position}

  @media (max-width: 768px) {
    ${(props) => props.responsivePosition}
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const Container = styled.div`
  z-index: 0;
  position: relative;

  span {
    bottom: 0px;
    width: 25px;
    height: 100px;
    display: block;
    position: absolute;
    border-radius: 15px;
    background: ${(props) => props.color} !important;
    animation: audio-wave 1.5s infinite cubic-bezier(0.22, 0.61, 0.36, 1);

    ${(props) =>
      props.opposite
        ? `
      &:first-child {
        animation-delay: 0s;
      }
    `
        : `
        &:first-child {
          animation-delay: 0s;
        }
        `}

    &:nth-child(${(props) => (props.opposite ? 10 : 2)}) {
      left: ${(props) => (props.opposite ? "315px" : "35px")};
      animation-delay: 0.1s;
    }

    &:nth-child(${(props) => (props.opposite ? 9 : 3)}) {
      left: ${(props) => (props.opposite ? "280px" : "70px")};
      animation-delay: 0.2s;
    }

    &:nth-child(${(props) => (props.opposite ? 8 : 4)}) {
      left: ${(props) => (props.opposite ? "245px" : "105px")};
      animation-delay: 0.3s;
    }

    &:nth-child(${(props) => (props.opposite ? 7 : 5)}) {
      left: ${(props) => (props.opposite ? "210px" : "140px")};
      animation-delay: 0.4s;
    }

    &:nth-child(6) {
      left: 175px;
      animation-delay: 0.5s;
    }

    &:nth-child(${(props) => (props.opposite ? 5 : 7)}) {
      left: ${(props) => (props.opposite ? "140px" : "210px")};
      animation-delay: 0.4s;
    }

    &:nth-child(${(props) => (props.opposite ? 4 : 8)}) {
      left: ${(props) => (props.opposite ? "105px" : "245px")};
      animation-delay: 0.3s;
    }

    &:nth-child(${(props) => (props.opposite ? 3 : 9)}) {
      left: ${(props) => (props.opposite ? "70px" : "280px")};
      animation-delay: 0.2s;
    }

    &:nth-child(${(props) => (props.opposite ? 2 : 10)}) {
      left: ${(props) => (props.opposite ? "35px" : "315px")};
      animation-delay: 0.1s;
    }
  }
`;
