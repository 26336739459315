/* base */
import styled from "styled-components";
import { colors } from "theme/colors";
import { fontsizes } from "theme/fontsizes";

export const TitleContainer = styled.div`
  top: 45%;
  z-index: 1;
  text-align: center;
  position: absolute;
  width: 70%;

  @media (max-width: 600px) {
    top: 40%;
  }
`;

export const Title = styled.h1`
  margin: 0;
  z-index: 2;
  letter-spacing: 15px;
  color: ${colors.white};
  font-size: ${fontsizes.title};

  @media (max-width: 400px) {
    letter-spacing: 10px;
  }
`;
