/* base */
import React from "react";
import Reveal from "react-awesome-reveal";
import { NavLink } from "react-router-dom";

/* assets */
import { colors } from "theme/colors";
import { customAnimation } from "modules/home/data";

/* components */
import { Module } from "components/module";
import { Grid, PGrid, InnerModule2 } from "modules/home/modules/module2/styles";

export const HomeModule2 = (props) => {
  const content1Splits = props.content1.split("Make Music Day");

  return (
    <Module
      padding="5vh 0 0 0;"
      background={colors.white}
      responsiveHeight="fit-content"
    >
      <InnerModule2>
        <Reveal triggerOnce keyframes={customAnimation}>
          <Grid imageURL={props.imageURL}>
            <div>
              <h2>
                <b>{props.title1}</b>
              </h2>
              <h2 className="anchor">{props.title2}</h2>
              <br />
              <br />
              <PGrid>
                <p id="1-third-h2">
                  {content1Splits[0]}
                  <a
                    href="https://www.makemusicday.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Make Music Day
                  </a>
                  {content1Splits[1]}
                </p>
                <p>
                  {props.content2}
                  <br />
                  <br />
                  <b>{props.slogan}</b>
                </p>
              </PGrid>
              <br />
              <br />
              <NavLink to="/about-festival">{props.button}</NavLink>
            </div>
            <div />
          </Grid>
        </Reveal>
      </InnerModule2>
    </Module>
  );
};
