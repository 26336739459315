import React from "react";
import get from "lodash/get";
import { Chevron } from "icons/Chevron";
import { useQuery } from "graphql-hooks";
import { Module } from "components/module";
import { LoaderComponent } from "components/loader";
import { useTitle, useUpdateEffect } from "react-use";
import useLocalStorageState from "use-local-storage-state";
import {
  Anchor,
  BackToTop,
  LineUpSlot,
  LineUpSpot,
  LineUpTitle,
  LineUpContainer,
  LineUpBackground,
  LineUpAnchorsContainer,
} from "./styles";
import { LOCAL_DATA } from "./data";

const BASE_LINE_UP_QUERY = `query Lineup($lang: SiteLocale) {
    lineup {
        spots(locale: $lang) {
            number
            name
            slot {
                time
                genre
                name
            }
        }
    }
}`;

export function LineUp() {
  useTitle("Line Up - Make Music Cyprus");

  const [showBackToTop, setShowBackToTop] = React.useState(false);

  const lang = useLocalStorageState("lang", {
    defaultValue: "en",
  })[0];
  const { loading, data, refetch } = useQuery(BASE_LINE_UP_QUERY, {
    variables: { lang: lang || "en" },
  });

  const [localdata, setlocadata] = React.useState(data ?? LOCAL_DATA);

  const handleBackToTopClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleAnchorClick = (spotNumber) => () => {
    const element = document.getElementById(`spot-${spotNumber}`);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useUpdateEffect(() => {
    refetch({
      lang: lang || "en",
    });
  }, [lang]);

  React.useEffect(() => {
    if (data) {
      setlocadata(data);
    }
  }, [data]);

  React.useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <Module
      display="flex"
      alignItems="center"
      padding="120px 0 0 0;"
      flexDirection="column"
      justifyContent="center"
      height="fit-content"
      responsiveHeight="fit-content"
      background="transparent"
    >
      <LineUpBackground />
      <LineUpContainer>
        <br />
        <br />
        <LineUpTitle>LINE UP {new Date().getFullYear()}</LineUpTitle>
        <br />
        <br />
        <LineUpAnchorsContainer>
          {get(localdata, "lineup.spots", []).map((spot) => (
            <Anchor
              key={`anchor-${spot.number}`}
              onClick={handleAnchorClick(spot.number)}
            >
              {spot.number < 10 ? "0" : ""}
              {spot.number} - {spot.name}
            </Anchor>
          ))}
        </LineUpAnchorsContainer>
        <br />
        <br />
        {get(localdata, "lineup.spots", []).map((spot) => (
          <LineUpSpot key={spot.number} id={`spot-${spot.number}`}>
            <h2>
              <span>
                {spot.number < 10 ? "0" : ""}
                {spot.number}
              </span>
              <br />
              <div>
                <div id="dot" />
                {spot.name}
              </div>
            </h2>
            <br />
            <br />
            <div>
              {get(spot, "slot", []).map((slot) => (
                <LineUpSlot key={`${slot.time}-${slot.name}`}>
                  <span>
                    {slot.time} | {slot.genre}
                  </span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: slot.name,
                    }}
                  />
                </LineUpSlot>
              ))}
            </div>
          </LineUpSpot>
        ))}
        <br />
        <br />
      </LineUpContainer>
      <BackToTop
        onClick={handleBackToTopClick}
        style={{
          display: showBackToTop ? "flex" : "none",
        }}
      >
        <Chevron fill="#fff" style={{ transform: "rotate(-90deg)" }} />
      </BackToTop>
    </Module>
  );
}
