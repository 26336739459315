import React from "react";
import { Chevron } from "icons/Chevron";

export const ScrollIndicator = (props) => {
  function onClick() {
    window.scrollTo({
      top: window.innerHeight,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <div
      onClick={onClick}
      style={{
        height: 24,
        left: "50vw",
        cursor: "pointer",
        position: "absolute",
        bottom: "calc(20vh - 35px)",
        animation: "scroll-indicator 1.5s infinite linear",
      }}
    >
      <Chevron fill={props.color} style={{ transform: "rotate(90deg)" }} />
    </div>
  );
};
