import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  overflow: hidden;
  position: relative;
  z-index: ${(props) => props.zIndex};
  display: ${(props) => props.display};
  padding: ${(props) => props.padding};
  background: ${(props) => props.background};
  align-items: ${(props) => props.alignItems};
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => props.justifyContent};
  ${(props) =>
    props.minHeight && !props.height
      ? `min-height: ${props.minHeight};`
      : `height: ${props.height || "100vh"};`}

  @media (max-width: 992px) {
    height: ${(props) => props.responsiveHeight};
    ${(props) =>
      props.responsiveMinHeight
        ? `min-height: ${props.responsiveMinHeight};`
        : ""}
  }

  ${(props) =>
    props.smHeight
      ? `
    @media (max-width: 768px) {
      height: ${props.smHeight};
    }
  `
      : ""}
`;

export function Module(props) {
  return <Container {...props}>{props.children}</Container>;
}
