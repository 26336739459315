/* base */
import styled from "styled-components";
import { NavLink } from "react-router-dom";

/* assets */
import { colors } from "theme/colors";
import { fontsizes } from "theme/fontsizes";
import { fontweights } from "theme/fontweights";
import { breakpoints } from "theme/breakpoints";

export const Container = styled.header`
  top: 0;
  width: 100%;
  display: flex;
  position: fixed;
  overflow: hidden;
  padding: 36px 10vw;
  box-sizing: border-box;
  flex-direction: column;
  transition: all 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
  background-color: ${(props) => (props.showHiddenNav ? props.color : "")};
  z-index: ${(props) => (!props.hideNav || props.showHiddenNav ? 1000 : 0)};

  > a {
    > img {
      width: 104px;
      height: 70px;
      margin-left: -17px;
      transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
    }
  }

  @media (min-width: 992px) and (max-width: 1440px) {
    padding: 20px 10vw;
  }
  @media (max-width: 991px) {
    height: 100px;
    padding: 20px 5vw;
  }
  @media (max-width: 767px) {
    padding: 0;
    height: 100px;
    overflow: unset;
    background-color: transparent;
  }
`;

export const LogoNavLink = styled(NavLink)`
  @media (max-width: 991px) {
    z-index: 1001;
    padding-top: 35px;
    > img {
      z-index: 1001;
      width: 94px !important;
      height: 63px !important;
    }
  }
`;

export const Nav = styled.nav`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  top: ${(props) => (props.hide ? "-150px" : "0")};
  transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  visibility: ${(props) => (props.hide ? "hidden" : "visible")};

  img {
    // width: 147px;
    // height: 98px;
    width: 94px;
    height: 63px;
    ${(props) => (props.hideNav ? "width: 94px;height: 63px;" : "")}
    ${(props) =>
      props.shrinkLogo ? "margin-left: -16px;" : "margin-left: -31px;"}
  }

  @media (max-width: 991px) {
    margin-top: 25px;
    justify-content: flex-end;
  }

  @media (max-width: 767px) {
    top: 0;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    margin-top: 0;
    position: fixed;
    flex-direction: column;
    justify-content: center;
    background: ${(props) => props.color};
    left: ${(props) => (props.hide ? "-50vh" : "0")};
  }
`;

export const Links = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  @media ${breakpoints.xl} {
    width: 65%;
  }
  @media ${breakpoints.lg} {
    width: 70%;
  }
  @media (max-width: 991px) {
    width: calc(100% - 130px);
  }
  @media (max-width: 767px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const StyledNavLink = styled(NavLink)`
  margin: 0 13px;
  position: relative;
  text-decoration: none;
  color: ${colors.white};
  font-size: ${fontsizes.navlink};
  font-weight: ${fontweights.navlink};

  @media (max-width: 767px) {
    margin: 0;
    width: 100%;
    padding: 30px 0;
    text-align: center;
    border-bottom: 1px solid ${colors.white};

    &:first-of-type {
      border-top: 1px solid ${colors.white};
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 0%;
    height: 1px;
    bottom: 0;
    margin-top: -0.5px;
    background: #fff;
  }

  &:before {
    left: -1.5px;
  }
  &:after {
    right: 2.5px;
    background: #fff;
    transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  &:hover:before {
    background: #fff;
    width: 100%;
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  &:hover:after {
    background: transparent;
    width: 100%;
    transition: 0s;
  }
`;

export const MenuButton = styled.div`
  right: 50px;
  z-index: 1001;
  cursor: pointer;
  padding: 3px 10px;
  position: fixed;
  border-radius: 5px;
  display: inline-block;
  color: ${colors.black};
  background: ${colors.white};
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  top: ${(props) => (props.show ? "-20vh" : "53px")};
  transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  visibility: ${(props) => (props.show ? "hidden" : "visible")};

  @media (max-width: 1440px) {
    top: ${(props) => (props.show ? "-20vh" : "36px")};
    visibility: ${(props) => (props.show ? "hidden" : "visible")};
  }

  @media (max-width: 991px) {
    ${(props) => (props.showHiddenNav ? `top: -20vh;visibility: hidden;` : "")}
  }

  @media (max-width: 767px) {
    right: 5vw;
    top: ${(props) => (props.show ? "-20vh" : "47px")};
    visibility: ${(props) => (props.show ? "hidden" : "visible")};
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 20px;
    height: 3px;
    background-color: ${(props) => props.color};
    margin: 4px 0;
    transition: 0.4s;
    border-radius: 10px;
  }

  ${(props) =>
    props.showHiddenNav
      ? `
      .bar1 {
        transform: rotate(-45deg) translate(-5px, 5px);
      }

      .bar2 {
        opacity: 0;
      }

      .bar3 {
        transform: rotate(45deg) translate(-5px, -5px);
      }
  `
      : ""}
`;

export const FixedButtons = styled.div`
  gap: 20px;
  top: 150px;
  right: 20px;
  z-index: 1001;
  display: flex;
  position: fixed;
  flex-direction: column;

  > a {
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 25px;
    text-decoration: none;
    letter-spacing: 3.5px;
    color: ${colors.black};
    background: ${colors.white};
    transition: background 0.5s ease;
    border: 2px solid ${colors.black};
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

    > img {
      margin-right: 10px;
    }

    &:hover {
      color: ${colors.white};
      background: ${colors.orange1};
      border-color: ${colors.orange1};
    }
  }

  @media (max-width: 767px) {
    gap: 0;
    left: 0;
    bottom: 0;
    top: unset;
    right: unset;
    width: 100vw;
    flex-direction: row;

    > a {
      border-radius: 0;
      text-align: center;
      width: ${(props) => (props.alternatePos ? "100" : "50")}%;
    }
  }
`;
