import React from "react";
import { colors } from "theme/colors";
import styled from "styled-components";
import { Module } from "components/module";

const MapContainer = styled.div`
  width: 80%;
  height: calc(100vh - 120px);

  iframe {
    border-style: none;
  }

  @media (max-width: 768px) {
    width: 90%;

    iframe {
      height: 90%;
    }
  }
`;

export function Map() {
  return (
    <Module
      display="flex"
      alignItems="center"
      padding="120px 0 0 0;"
      flexDirection="column"
      justifyContent="center"
      height="fit-content"
      responsiveHeight="fit-content"
      background={colors.orange1}
    >
      <MapContainer>
        <iframe
          title="map"
          width="100%"
          height="95%"
          src="https://www.google.com/maps/d/embed?mid=11YX_R1CiWs-RL3W1DyO_Ifct0OAD48k&ehbc=2E312F"
        />
      </MapContainer>
    </Module>
  );
}
