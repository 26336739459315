export const LOCAL_DATA = {
  lineup: {
    spots: [
      {
        number: 1,
        name: "Omerye Square (Mikri Arktos)",
        slot: [
          {
            time: "18:00",
            genre: "Traditional Cypriot",
            name: "Despina Zorpa",
          },
          {
            time: "18:35",
            genre: "Pop",
            name: "Athena Marti",
          },
          {
            time: "19:00",
            genre: "Various Styles",
            name: "Natalia V. Simmonds-Mosquera",
          },
          {
            time: "19:30",
            genre: "Rebetiko",
            name: "Rembetiki Fa Trias",
          },
          {
            time: "20:20",
            genre: "Light Laiko",
            name: "Old Greek cinema",
          },
          {
            time: "21:15",
            genre: "Ballads",
            name: "Kiklothimiki",
          },
          {
            time: "22:15",
            genre: "Rebetiko",
            name: "Anaren Mpourekk",
          },
        ],
      },
      {
        number: 2,
        name: "Nicosia Municipality",
        slot: [
          {
            time: "17:00",
            genre: "Pop",
            name: "Open School of Strovolos Municipality",
          },
          {
            time: "17:40",
            genre: "Musicals",
            name: "Colorful Voices",
          },
          {
            time: "18:20",
            genre: "Entekhno",
            name: "A piacere Choir",
          },
          {
            time: "19:00",
            genre: "Greek Music",
            name: "Municipal Choir of Tseri & Antigoni's music studio",
          },
          {
            time: "19:40",
            genre: "Traditional & Modern",
            name: "ARMONIYA Choir",
          },
          {
            time: "20:15",
            genre: "Pop",
            name: "Mariaandrea Socratous",
          },
          {
            time: "20:45",
            genre: "Tradiotional Cypriot",
            name: "Anerades",
          },
        ],
      },
      {
        number: 3,
        name: "Dimarchias Square",
        slot: [
          {
            time: "17:00",
            genre: "Rock, Jazz, Indie originals",
            name: "SICMHO",
          },
          {
            time: "17:45",
            genre: "Rock",
            name: "Buzz",
          },
          {
            time: "18:30",
            genre: "Metal",
            name: "Desert Squids",
          },
          {
            time: "19:25",
            genre: "Heavy Metal",
            name: "Galaxy Cat",
          },
          {
            time: "19:55",
            genre: "Various Styles",
            name: "Sophia Kadis' Band",
          },
          {
            time: "20:35",
            genre: "Alternative Rock",
            name: "On fire",
          },
          {
            time: "21:20",
            genre: "Rock",
            name: "Leave The Wave",
          },
          {
            time: "22:10",
            genre: "Rock",
            name: "SISYPHOS",
          },
          {
            time: "23:10",
            genre: "Rock & Blues",
            name: "Michael Samsonadze - ArtLive Studio",
          },
        ],
      },
      {
        number: 4,
        name: "Asklipiou Street",
        slot: [
          {
            time: "17:00",
            genre: "Indie",
            name: "Tim Koehn",
          },
          {
            time: "17:45",
            genre: "Acoustic",
            name: "Bob Yiannis",
          },
          {
            time: "18:40",
            genre: "Indie",
            name: "Eleanor Grace & Emily Piper",
          },
          {
            time: "19:20",
            genre: "Indie, Blues",
            name: "Theo Chacholiades",
          },
          {
            time: "19:50",
            genre: "Various Styles",
            name: "Mystical Deer",
          },
          {
            time: "20:40",
            genre: "Acoustic",
            name: "PANAYIOTIS KOUTROUZA",
          },
          {
            time: "21:35",
            genre: "American",
            name: "El Chele",
          },
        ],
      },
      {
        number: 5,
        name: "Faneromeni Square",
        slot: [
          {
            time: "17:00",
            genre: "Classical",
            name: "Duende",
          },
          {
            time: "17:40",
            genre: "Traditional",
            name: "Traditional Music Ensemble - Nicosia Music School",
          },
          {
            time: "18:15",
            genre: "Pop rock",
            name: "Panic Station",
          },
          {
            time: "19:00",
            genre: "Acoustic",
            name: "George Vrakas",
          },
          {
            time: "19:45",
            genre: "-",
            name: "Greetings from MMC festival team,<br/>in the presence of the Deputy Minister of Culture Dr. Vasiliki Kassianidou",
          },
          {
            time: "20:00",
            genre: "Retro",
            name: "Those were the days...",
          },
          {
            time: "21:00",
            genre: "Pop",
            name: "Jenny Karapataki",
          },
          {
            time: "22:00",
            genre: "Pop",
            name: "Windcraft Band",
          },
          {
            time: "23:00",
            genre: "Classical",
            name: "Zina Melekki Quintet",
          },
        ],
      },
      {
        number: 6,
        name: "Onasagorou Street (Mousikon Kafeneio)",
        slot: [
          {
            time: "17:00",
            genre: "Classical",
            name: "Sotiris Kasparides",
          },
          {
            time: "17:25",
            genre: "Acoustic",
            name: "jo",
          },
          {
            time: "17:50",
            genre: "Pop rock",
            name: "Andreas Theodosiou",
          },
          {
            time: "18:30",
            genre: "Jazz",
            name: "Valeria Iorgu-Andreas Poupazis",
          },
          {
            time: "19:05",
            genre: "Greek Rock",
            name: "Constantinos Hadjipolycarpou",
          },
          {
            time: "19:40",
            genre: "Entekhno",
            name: "Chrysanthi Schiza",
          },
        ],
      },
      {
        number: 7,
        name: "Kostaki Pantelidi Street",
        slot: [
          {
            time: "17:00",
            genre: "Acoustic",
            name: "J group",
          },
          {
            time: "17:40",
            genre: "Classical",
            name: "Emilios Charalambous",
          },
          {
            time: "17:50",
            genre: "Pop",
            name: "Voice N Keys",
          },
          {
            time: "18:30",
            genre: "Soundtracks",
            name: "Irena Avraamidou",
          },
          {
            time: "18:55",
            genre: "Pop",
            name: "Sophia Argyrou",
          },
          {
            time: "19:10",
            genre: "Soundtracks",
            name: "Eleni Anthousi",
          },
          {
            time: "19:25",
            genre: "Hip Hop",
            name: "M.Telo",
          },
          {
            time: "19:45",
            genre: "Improvised",
            name: "Matin Bassami",
          },
        ],
      },
      {
        number: 8,
        name: "Ledras-Onasagorou Junction",
        slot: [
          {
            time: "19:00",
            genre: "Interactive Activity",
            name: "Costis Kouloumis<br/>Pithkiavli: Cypriot Wind Instrument",
          },
        ],
      },
      {
        number: 9,
        name: "Eleftheria Square Bridge",
        slot: [
          {
            time: "17:00",
            genre: "-",
            name: "ANT1 Live Link",
          },
          {
            time: "17:00",
            genre: "-",
            name: "Karaiskakio Foundation (Bone Marrow Sample Collection)",
          },
          {
            time: "17:30",
            genre: "-",
            name: '"Music Instruments from clay"<br />ADHD ASSOCIATION',
          },
          {
            time: "20:15",
            genre: "Various Styles",
            name: "Joint concert of the Cyprus Police Band, the Cypriot National Guard and the Symphonic Band of Strovolos Municipality-European University Cyprus",
          },
        ],
      },
      {
        number: 10,
        name: "Eleftheria Square Moat",
        slot: [
          {
            time: "17:00",
            genre: "Interactive Activity",
            name: '"Meet the world of percussion instruments"<br/>Partassides Music Store',
          },
          {
            time: "18:00",
            genre: "Classical",
            name: "Dona Amyrotou",
          },
          {
            time: "18:15",
            genre: "Classical",
            name: "DrunLaB",
          },
          {
            time: "18:45",
            genre: "Various Styles",
            name: "Harps",
          },
        ],
      },
      {
        number: 11,
        name: "Eleftheria Square Moat",
        slot: [
          {
            time: "17:00",
            genre: "Interactive Activity",
            name: '"Snake & Ladders"<br/>KyklOIKOdromio',
          },
        ],
      },
      {
        number: 12,
        name: "Eleftheria Square Amphitheatre",
        slot: [
          {
            time: "17:00",
            genre: "Pop rock",
            name: "MusicJam Rock Concert",
          },
          {
            time: "17:25",
            genre: "Classical",
            name: "AMusEd students",
          },
          {
            time: "18:05",
            genre: "Various Styles",
            name: "DIAPASON Music Lab.",
          },
          {
            time: "18:50",
            genre: "Various Styles",
            name: "Liberty Music & Arts School",
          },
          {
            time: "19:30",
            genre: "Various Styles",
            name: '"It\'s all about music"<br/>(Anafandon Multiculture Art Space)',
          },
          {
            time: "20:00",
            genre: "African",
            name: "NATIONAL TEAM",
          },
        ],
      },
      {
        number: 13,
        name: "Zena Palace",
        slot: [
          {
            time: "18:00",
            genre: "DJ Set",
            name: "Alexander Mart",
          },
          {
            time: "19:00",
            genre: "DJ Set",
            name: "That George",
          },
          {
            time: "20:00",
            genre: "DJ Set",
            name: "Not Mature Enough",
          },
          {
            time: "22:00",
            genre: "DJ Set",
            name: "Paul Anthonee",
          },
          {
            time: "21:00",
            genre: "DJ Set",
            name: "Jonathan Reyes",
          },
          {
            time: "23:00",
            genre: "DJ Set",
            name: "Paul Angelo & Don Argento",
          },
        ],
      },
      {
        number: 14,
        name: "Makariou Square (360 Nicosia)",
        slot: [
          {
            time: "18:00",
            genre: "Acoustic",
            name: "Julia Aleksandra",
          },
          {
            time: "18:55",
            genre: "Various Genres",
            name: "Mixtape",
          },
          {
            time: "19:55",
            genre: "Alternative Rock",
            name: "Anaeve",
          },
          {
            time: "20:50",
            genre: "Pop rock",
            name: "Funked-Up Project",
          },
          {
            time: "22:00",
            genre: "Rock",
            name: "Stranger Pulse",
          },
          {
            time: "23:05",
            genre: "Techno - Syrian Folk Fusion",
            name: "The Greek Samurai x Eisa B",
          },
        ],
      },
    ],
  },
};
