import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    background: ${(props) => props.color};
  }
`;

const Bar = styled.div`
  width: 100px;
  height: 10px;
  margin: 4px 0;
  border-radius: 15px;
  animation: ${(props) => props.keyframe} 1.5s infinite linear;
`;

export const TitleElement = (props) => (
  <Container color={props.color}>
    <Bar keyframe="horizontal-bars-1"></Bar>
    <Bar keyframe="horizontal-bars-2"></Bar>
    <Bar keyframe="horizontal-bars-1"></Bar>
    <Bar keyframe="horizontal-bars-2"></Bar>
    <Bar keyframe="horizontal-bars-1"></Bar>
  </Container>
);
