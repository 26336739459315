/* base */
import React from "react";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import { useQuery } from "graphql-hooks";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { isInViewport } from "utils/isInViewport";
import { useTitle, useUpdateEffect } from "react-use";
import useLocalStorageState from "use-local-storage-state";

/* assets */
import { colors } from "theme/colors";

/* components */
import { Module } from "components/module";
import { Footer } from "components/footer";
import { LoaderComponent } from "components/loader";
import { Title, Section1, Subtitle } from "modules/about/styles";
import {
  Section2,
  // Section2Down,
  Section2Text,
  Section2Up,
  FormContainer,
} from "modules/volunteer/styles";
import { TitleElement } from "./components/titleelement";
import { ScrollIndicator } from "components/scrollindicator";
import { VolunteerForm } from "./components/form";

const background1 = `linear-gradient(180deg, ${colors.orange2} 80%, ${colors.white} 20%);-moz-linear-gradient(180deg, ${colors.orange2} 80%, ${colors.white} 20%);-o-linear-gradient(180deg, ${colors.orange2} 80%, ${colors.white} 20%);-webkit-linear-gradient(180deg, ${colors.orange2} 80%, ${colors.white} 20%);`;

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -200px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const BASE_VOLUNTEER_QUERY = `query Volunteer($lang: SiteLocale) {
  volunteer {
    title(locale: $lang)
    subtitle(locale: $lang)
    section1SloganSegment1(locale: $lang)
    section1SloganSegment2(locale: $lang)
  }
}`;

export function VolunteerModule() {
  useTitle("Volunteer - Make Music Cyprus");

  // const lang = useLocalStorageState("lang", {
  //   defaultValue: "en",
  // })[0];
  const lang = "en";
  const { loading, data, refetch } = useQuery(BASE_VOLUNTEER_QUERY, {
    variables: { lang: lang || "en" },
  });

  function onScroll() {
    const anchors = document.getElementsByClassName("anchor");
    if (anchors) {
      const element1 = document.getElementById("anchor-1");
      if (element1) {
        const result1 = isInViewport(element1);
        if (result1) {
          anchors[0].classList.add("title-line-1-3");
        } else {
          anchors[0].classList.remove("title-line-1-3");
        }
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useUpdateEffect(() => {
    refetch({
      lang: lang || "en",
    });
  }, [lang]);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Make Music Cyprus - THERE IS ALWAYS SPACE FOR AN EXTRA PAIR OF HANDS AND A SUPPORTIVE MIND"
        />
      </Helmet>
      <Module background={background1}>
        <Section1>
          <TitleElement color={colors.white} />
          <Title>{get(data, "volunteer.title", "VOLUNTEER")}</Title>
          <Subtitle>
            {get(data, "volunteer.subtitle", "TOGETHER WE CAN DO GREAT THINGS")}
          </Subtitle>
        </Section1>
        <ScrollIndicator color={colors.orange2} />
      </Module>
      <br />
      <Module height="fit-content">
        <Reveal triggerOnce keyframes={customAnimation}>
          <Section2>
            <div
              id="participate-anim"
              style={{
                top: "25%",
                right: "102px",
                opacity: "0.5",
                position: "absolute",
                transform: "scale(2.5) rotate(180deg)",
              }}
            >
              <TitleElement color={colors.bgyellow3} />
            </div>
            <Section2Up>
              <Section2Text>
                {get(
                  data,
                  "volunteer.section1SloganSegment1",
                  "JOIN THE WONDERFUL TEAM OF"
                )}
                <br />
                <b>
                  {get(
                    data,
                    "volunteer.section1SloganSegment2",
                    "MAKE MUSIC FESTIVAL"
                  )}
                </b>
              </Section2Text>
            </Section2Up>
            {/* <Section2Down oppositeLine>
              <Section2Text id="anchor-1" className="anchor">
                <b>VOLUNTEER INTEREST FORM</b>
              </Section2Text>
              <h4>Join our team!</h4>
            </Section2Down> */}
          </Section2>
        </Reveal>
        <FormContainer id="form">
          <div>
            <VolunteerForm />
          </div>
        </FormContainer>
      </Module>
      <Footer />
    </React.Fragment>
  );
}
