import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  margin-top: -60px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  opacity: 0.1;
  margin: 0 8px;
  animation: leadanimation 1.5s infinite linear;

  &:nth-child(1) {
    animation-delay: 0s;
    border-top: 35px solid transparent;
    border-bottom: 32px solid transparent;
    border-left: 45px solid ${(props) => props.color};
  }
  &:nth-child(2) {
    animation-delay: 0.3s;
    border-top: 25px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 35px solid ${(props) => props.color};
  }
  &:nth-child(3) {
    animation-delay: 0.6s;
    border-top: 15px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 25px solid ${(props) => props.color};
  }
`;

export const TitleElement = (props) => (
  <Container>
    <Triangle color={props.color} />
    <Triangle color={props.color} />
    <Triangle color={props.color} />
  </Container>
);
