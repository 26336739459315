import React from "react";

export const InstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.130434mm"
    height="19.130787mm"
    viewBox="0 0 19.130434 19.130787"
  >
    <g transform="translate(20.14855,85.160631)">
      <path
        d="m -5.6412698,-73.279429 c 0,1.375481 -1.11513,2.490611 -2.490964,2.490611 h -4.7861352 c -1.375834,0 -2.490964,-1.11513 -2.490964,-2.490611 v -4.786841 c 0,-1.375481 1.11513,-2.490611 2.490964,-2.490611 h 4.7861352 c 1.375834,0 2.490964,1.11513 2.490964,2.490611 z m 1.135945,-4.786841 c 0,-2.003072 -1.623837,-3.626556 -3.626909,-3.626556 h -4.7861352 c -2.003073,0 -3.626556,1.623484 -3.626556,3.626556 v 4.786841 c 0,2.00272 1.623483,3.626556 3.626556,3.626556 h 4.7861352 c 2.003072,0 3.626909,-1.623836 3.626909,-3.626556 z m -5.9993392,4.375855 c -1.050572,0 -1.905,-0.854427 -1.905,-1.904647 0,-1.050219 0.854428,-1.904647 1.905,-1.904647 1.0498662,0 1.9042942,0.854428 1.9042942,1.904647 0,1.05022 -0.854428,1.904647 -1.9042942,1.904647 z m 0,-4.878211 c -1.639711,0 -2.973564,1.333853 -2.973564,2.973564 0,1.639358 1.333853,2.973211 2.973564,2.973211 1.6393582,0 2.9728582,-1.333853 2.9728582,-2.973211 0,-1.639711 -1.3335,-2.973564 -2.9728582,-2.973564 z m 3.1467772,-0.907344 c -0.39123,0 -0.708024,0.316794 -0.708024,0.707672 0,0.390878 0.316794,0.707672 0.708024,0.707672 0.390878,0 0.70732,-0.316794 0.70732,-0.707672 0,-0.390878 -0.316442,-0.707672 -0.70732,-0.707672 z m -3.2254462,-4.775906 c -4.773436,0 -8.656814,3.883378 -8.656814,8.656814 0,4.773083 3.883378,8.656461 8.656814,8.656461 4.7730832,0 8.6564602,-3.883378 8.6564602,-8.656461 0,-4.773436 -3.883377,-8.656814 -8.6564602,-8.656814 z m 0,18.222031 c -5.274381,0 -9.565217,-4.291189 -9.565217,-9.565217 0,-5.27438 4.290836,-9.565569 9.565217,-9.565569 5.2740272,0 9.5652162,4.291189 9.5652162,9.565569 0,5.274028 -4.291189,9.565217 -9.5652162,9.565217"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.0352777,
        }}
      />
    </g>
  </svg>
);
