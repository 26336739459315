/* base */
import styled from "styled-components";
import { misc } from "theme/misc";
import { colors } from "theme/colors";
import { fontsizes } from "theme/fontsizes";
import { fontweights } from "theme/fontweights";
import HomeBigImage from "images/homeslide2.jpg";

export const InnerModule2 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Grid = styled.div`
  height: 100vh;
  display: flex;
  padding: 0 10vw;
  flex-direction: row;
  align-items: center;
  background: ${colors.white};

  @media (max-width: 768px) {
    height: fit-content;
    flex-direction: column;
  }

  > div:first-of-type {
    width: 60%;
    height: 100%;
    display: flex;
    padding-right: 100px;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1200px) {
      width: 100%;
      padding-right: 0;
      margin-bottom: calc(35vh * 17.5 / 100);
    }

    > h2 {
      margin: 5px 0;
      text-transform: uppercase;
      font-size: ${fontsizes.subtitle};
      font-weight: ${fontweights.subtitle};

      span {
        font-weight: 100;
        font-family: Open;
        letter-spacing: 8px;
      }
    }

    .anchor {
      position: relative;

      &:after {
        content: "";
        width: 100vw;
        bottom: -15px;
        position: absolute;
        border: 1px solid ${colors.black};
        transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
        // transform: translateX(
        //   ${(props) => (props.showLine ? "-100%" : "-200%")}
        // );
        transform: translateX(-200%);
      }
    }

    > a {
      width: fit-content;
      // padding: 30px 80px;
      padding: 15px 80px;
      border-radius: 25px;
      text-decoration: none;
      color: ${colors.black};
      background: ${colors.clear};
      font-size: ${fontsizes.navlink};
      transition: background 0.5s ease;
      border: 2px solid ${colors.black};
      font-weight: ${fontweights.navlinkbold};

      @media (max-width: 1200px) {
        padding: 15px 40px;
      }

      &:hover {
        color: ${colors.white};
        background: ${colors.orange1};
        border-color: ${colors.orange1};
      }
    }
  }

  > div:nth-of-type(2) {
    width: 40%;
    height: 80%;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    box-shadow: ${misc.boxshadow};
    background-image: url(${(props) => props.imageURL ?? HomeBigImage});

    @media (max-width: 768px) {
      width: 100%;
      height: 22vh;
      margin-bottom: calc(35vh * 17.5 / 100);
    }
  }
`;

export const PGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  > p {
    // width: 35%;
    min-width: 300px;
    max-width: 400px;
    font-size: ${fontsizes.text};
    &:first-of-type {
      margin-right: 70px;
    }
  }

  a {
    white-space: nowrap;
    text-decoration: none;
    color: ${colors.orange1};
  }
`;
