/* base */
import styled from "styled-components";
import { colors } from "theme/colors";

export const Container = styled.div`
  z-index: 3;
  width: 100%;
  display: grid;
  grid-gap: 18px;
  min-height: calc(90vh - 300px);
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 991px) {
    height: auto;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 767px) {
    height: auto;
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  flex: 1;
  height: auto;
  min-height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: box-shadow 0.3s ease-in-out;
  background-image: url("${(props) => props.src}");
  box-shadow: inset 0px 0px 0px 25px ${colors.white};

  @media (max-width: 767px) {
    &:nth-of-type(4) {
      display: none;
    }
    &:nth-of-type(5) {
      display: none;
    }
    &:nth-of-type(6) {
      display: none;
    }
  }

  &:hover {
    box-shadow: inset 0px 0px 0px 0px ${colors.white};
  }
`;
