/* base */
import styled from "styled-components";
import { misc } from "theme/misc";

export const InnerModule3 = styled.div`
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 992px) {
  }

  > iframe {
    z-index: 2;
    width: 55%;
    height: 65%;
    box-shadow: ${misc.boxshadow};

    @media (max-width: 992px) {
      width: 80%;
    }
  }
`;

export const Module3PaddingTop = styled.div`
  width: 100%;
  height: 17.5%;

  @media (max-width: 992px) {
  }
`;

export const VideoBG = styled.div`
  opacity: ${(props) => props.opacity};
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.url});

  @media (max-width: 992px) {
    height: 100%;
  }
`;
