/* base */
import { colors } from "theme/colors";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { fontsizes } from "theme/fontsizes";

export const Container = styled.div`
  display: flex;
  padding: 26px;
  position: relative;
  border-radius: 12px;
  flex-direction: column;
  color: ${colors.black};
  background-color: ${colors.bgwhite};
`;

export const Animation = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const Title = styled.h2`
  font-size: 2em;
  text-align: left;
  font-weight: 800;
`;

export const Subtitle = styled.h4`
  font-size: 1.5em;
  text-align: left;
  font-weight: 600;
`;

export const Body = styled.p`
  font-size: 1.2em;
`;

export const CustomNavLink = styled(NavLink)`
  bottom: 26px;
  font-weight: 700;
  position: absolute;
  width: fit-content;
  padding: 10px 30px;
  border-radius: 25px;
  text-decoration: none;
  color: ${colors.black};
  background: ${colors.clear};
  font-size: ${fontsizes.navlink};
  transition: background 0.5s ease;
  border: 2px solid ${colors.black};

  @media (max-width: 767px) {
    padding: 15px 40px;
  }

  &:hover {
    color: ${colors.white};
    background: ${colors.bgred2};
    border-color: ${colors.bgred2};
  }
`;
