import React from "react";
import axios from "axios";
import get from "lodash/get";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";

import { colors } from "theme/colors";
import { fontsizes } from "theme/fontsizes";
import { fontweights } from "theme/fontweights";

const RECAPTCHA_KEY = "6Ldj7dwZAAAAAIuvTcDuRBwd0XUSQsYOm83u9lgp";
const CORS_PROXY = "https://cors.mlt.nyuki.io/";
const FORM_ACTION =
  "https://docs.google.com/forms/d/e/1FAIpQLSeHHsDTyVy1_voUH9yuMiTMYDaZKXvbbkrLnWbpdOMifXxuSQ/formResponse";
const INPUT_1 = "entry.1059115465";
const INPUT_2 = "entry.339688574";
const INPUT_3 = "entry.1967416447";
const INPUT_4 = "entry.1191766269";

// test form
// const FORM_ACTION =
//     "https://docs.google.com/forms/d/e/1FAIpQLScS1JUD3peysJSfairvgFNnJsZEQtU9mcwhpo7qS9ZO6OwN8g/formResponse";
// const INPUT_1 = "entry.1905318717";
// const INPUT_2 = "entry.648390779";
// const INPUT_3 = "entry.1782069173";
// const INPUT_4 = "entry.189423838";

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const Form = styled.form`
  display: flex;
  max-width: 579px;
  width: fit-content;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Label = styled.label`
  font-size: 16px;
  color: ${(props) => props.color || colors.black};
`;

const InputField = styled.input`
  outline: none;
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 25px;
  margin: 10px 0 20px 0;
  border: 1px solid ${colors.black};

  user-select: text !important;
  -webkit-user-select: text !important;

  @media (max-width: 579px) {
    width: auto;
  }
`;

const SubmitBtn = styled.button`
  outline: none;
  margin-top: 20px;
  width: fit-content;
  padding: 15px 80px;
  border-radius: 25px;
  text-decoration: none;
  color: ${colors.black};
  background: ${colors.clear};
  font-size: ${fontsizes.navlink};
  transition: background 0.5s ease;
  border: 2px solid ${colors.black};
  font-weight: ${fontweights.navlinkbold};

  @media (max-width: 768px) {
    padding: 15px 40px;
  }

  &:hover {
    cursor: pointer;
    color: ${colors.white};
    background: ${colors.orange1};
    border-color: ${colors.orange1};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    color: ${colors.black};
    background: ${colors.clear};
    border-color: ${colors.black};
  }
`;

const Message = styled.div`
  font-size: 16px;
  color: ${(props) => props.color};
`;

const asterisk = <span style={{ color: colors.orange1 }}>*</span>;

export function SubscribeForm(props) {
  const [input1, setInput1] = React.useState("");
  const [input2, setInput2] = React.useState("");
  const [input3, setInput3] = React.useState("");
  const [input4, setInput4] = React.useState("");
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [emailInvalid, setEmailInvalid] = React.useState(false);

  const recaptchaRef = React.useRef();
  const [recaptcha, setRecaptcha] = React.useState(false);

  function emailValidation(value) {
    return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (emailValidation(input4)) {
      setEmailInvalid(false);
      const formData = new FormData();
      formData.append(INPUT_1, input1);
      formData.append(INPUT_2, input2);
      formData.append(INPUT_3, input3);
      formData.append(INPUT_4, input4);
      axios
        .post(CORS_PROXY + FORM_ACTION, formData)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setSuccess(true);
            setInput1("");
            setInput2("");
            setInput3("");
            setInput4("");
            setRecaptcha(false);
            recaptchaRef.current.reset();
          } else {
            setError(true);
          }
        })
        .catch(() => setError(true));
    } else {
      setEmailInvalid(true);
    }
  }

  const submitDisabled =
    input1.length === 0 ||
    input2.length === 0 ||
    input4.length === 0 ||
    !emailValidation(input4) ||
    !recaptcha;

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Label color={colors.orange1}>
          {get(props.data, "contact.required", "* Required")}
        </Label>
        <br />
        <br />
        <Label>
          {get(props.data, "contact.firstName", "First Name")} {asterisk}
        </Label>
        <InputField
          type="text"
          value={input1}
          onChange={(e) => setInput1(e.target.value)}
        />
        <Label>
          {get(props.data, "contact.lastName", "Last Name")} {asterisk}
        </Label>
        <InputField
          type="text"
          value={input2}
          onChange={(e) => setInput2(e.target.value)}
        />
        <Label>
          {get(
            props.data,
            "contact.orgName",
            "Name of Organisation or Company (if applicable)"
          )}{" "}
          {asterisk}
        </Label>
        <InputField
          type="text"
          value={input3}
          onChange={(e) => setInput3(e.target.value)}
        />
        <Label>
          {get(props.data, "contact.emailAddress", "Email Address")} {asterisk}
        </Label>
        <InputField
          type="text"
          value={input4}
          onChange={(e) => {
            if (!emailValidation(e.target.value)) {
              setEmailInvalid(true);
            } else {
              setEmailInvalid(false);
            }
            setInput4(e.target.value);
          }}
        />
        {emailInvalid && (
          <React.Fragment>
            <Message style={{ marginTop: -10 }} color={colors.bgred1}>
              {get(
                props.data,
                "contact.invalidEmail",
                "Please enter a valid email address."
              )}
            </Message>
            <br />
          </React.Fragment>
        )}
        <br />
        <ReCAPTCHA
          ref={recaptchaRef}
          onChange={setRecaptcha}
          sitekey={RECAPTCHA_KEY}
        />
        <br />
        <SubmitBtn type="submit" disabled={submitDisabled}>
          {get(props.data, "contact.submit", "Submit")}
        </SubmitBtn>
        <br />
        <br />
        {error && (
          <Message color={colors.bgred1}>Oops! Something went wrong.</Message>
        )}
        {success && (
          <Message color={colors.orange1}>
            Hooray! Your submission was successful!
          </Message>
        )}
      </Form>
    </Container>
  );
}
