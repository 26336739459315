/* base */
import styled from "styled-components";
import { colors } from "theme/colors";
import { fontsizes } from "theme/fontsizes";
import { fontweights } from "theme/fontweights";

export const Section1 = styled.div`
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h1`
  margin: 0;
  color: ${colors.white};
  font-size: ${fontsizes.detailpagetitle};

  @media (max-width: 500px) {
    font-size: 50px;
  }
`;

export const Subtitle = styled.h2`
  margin: 0;
  color: ${(props) => props.color ?? colors.white};
  font-size: ${fontsizes.subtitle};
  font-weight: ${fontweights.pagesub};

  @media (max-width: 500px) {
    font-size: 23px;
  }
`;

export const Section2 = styled.div`
  height: 100%;
`;

export const SectionTitle = styled.div`
  @media (max-width: 767px) {
    padding: 0 3vh;
  }

  > h3 {
    margin: 0;
    width: 100%;
    padding: 10px 0;
    text-align: start;
    color: ${colors.black};
    font-size: ${fontsizes.subtitle};
    font-weight: ${fontweights.pagesub};
  }
  .anchor {
    position: relative;
    &:before,
    &:after {
      content: "";
      width: 100vw;
      bottom: -15px;
      position: absolute;
      border: 1px solid ${colors.black};
      transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
      // transform: translateX(${(props) => (props.showLine ? "" : "200%")});
      transform: translateX(${(props) => (props.oppositeLine ? "-" : "")}200%);
    }
  }

  > h4 {
    margin: 25px 0;
    color: ${colors.black};
    font-size: ${fontsizes.h4};
    font-weight: ${fontweights["600"]};

    @media (max-width: 767px) {
      > br {
        display: none;
      }
    }
  }
`;

export const Section2Boxes = styled.div`
  width: 900px;
  display: flex;
  margin-top: 20px;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 991px) {
    width: 100%;
    align-items: center;
    flex-direction: column;
  }

  .section-2-box {
    z-index: 1;
    height: 220px;
    margin: 0 10px;
    position: relative;
    width: calc(100% / 3 - 15px);
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 35px;
    position: relative;
    color: ${colors.white};
    background: ${colors.bgblue1};
    line-height: 23px;

    * {
      pointer-events: none;
    }

    @media (max-width: 991px) {
      margin: 15px 0;
      width: calc(90% - 70px);
    }

    > h6 {
      font-weight: 800;
      margin: 0 0 20px 0;
      font-size: 26px !important;
    }

    > div {
      font-weight: 600;
      font-size: 19.5px;
    }

    .paragraph-container {
      height: 100%;
      display: flex;
      align-items: center;
    }

    p {
      font-size: 16px;
    }
  }
`;

export const Boxes = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => (props.big ? "20px" : "40px")};

  ${(props) =>
    props.huge
      ? `
    > div:first-of-type, > div:nth-of-type(2) {
      height: 460px;
      background: ${colors.bgblue1};

      @media (max-width: 1499px) {
        height: 410px;
      }
      @media (max-width: 768px) {
        height: auto;
      }
    }
  `
      : ""}

  ${(props) =>
    props.big
      ? `
    > div:first-of-type, > div:nth-of-type(2) {
      height: 330px;
      background: ${colors.bgblue1};

      @media (max-width: 1499px) {
        height: 280px;
      }
      @media (max-width: 768px) {
        height: auto;
      }
    }
  `
      : ""}

  ${(props) =>
    props.mid
      ? `
    > div:first-of-type, > div:nth-of-type(2) {
      height: auto;
      background: ${colors.bgblue1};

      @media (max-width: 767px) {
        height: auto;
      }
    }
  `
      : ""}

  @media (max-width: 991px) {
    flex-direction: column;

    > div:nth-of-type(2) {
      margin-top: 30px;
    }
  }
`;

export const Box = styled.div`
  z-index: 1;
  width: 350px;
  height: 230px;
  padding: 35px;
  margin: 0 15px;
  line-height: 23px;
  position: relative;
  border-radius: 10px;
  color: ${(props) => props.textColor ?? colors.white};
  font-size: ${fontsizes.text};
  background: ${(props) => props.color || colors.bgblue1} !important;
  ${(props) =>
    props.noCol
      ? ""
      : `
    column-count: 1;
    column-gap: 100px;

    @media (max-width: 1499px) {
      height: 210px;
    }
  `}

  &:first-of-type {
    z-index: 2;
  }

  @media (max-width: 768px) {
    height: auto;
    column-count: 0;
  }

  @media (max-width: 400px) {
    margin: 15px 0;
    width: calc(90% - 70px);
  }
`;

export const BoxImage = styled.div`
  width: 420px;
  height: 300px;
  margin: 0 15px;
  border-radius: 10px;

  @media (max-width: 991px) {
    margin: 15px 0;
    height: ${(props) => props.smHeight} !important;
  }

  @media (max-width: 450px) {
    width: calc(100% - 35px);
  }

  > div:nth-of-type(2) {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 10px;
    ${(props) =>
      props.bgimg
        ? `
          background-image: url(${props.bgimg});
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;`
        : ""}
  }
`;

export const MobileSpacer = styled.div`
  width: 100%;
  height: 10vh;
  background: ${(props) => props.color || ""};
  display: ${(props) => (props.alwaysView ? "flex" : "none")};

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const SmTextBoxes = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  > div:first-of-type {
    margin-bottom: 20px;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;
